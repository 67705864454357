import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import InnerWindow from '../../components/InnerWindow';
import clsx from 'clsx';
import cleanStatusOk from '../../res/images/clean_status_ok.svg';
import cleanStatusFail from '../../res/images/clean_status_fail.svg';
import minimizeIcon from '../../res/images/minimize.svg';
import maximizeIcon from '../../res/images/maximize.svg';
import arrowDown from '../../res/images/arrow_drop_down.svg';
import arrowUp from '../../res/images/arrow_drop_up.svg';
import durationWarning from '../../res/images/duration_warning.svg';
import DateTime from 'luxon/src/datetime';
import openMachineIcon from '../../res/images/machine_circle_arrow.svg';
import downloadIcon from '../../res/images/download.svg';
import { STATUS_GROUPS } from './StatusGroups';
import { CircularProgress, IconButton, Popover } from '@material-ui/core';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import SearchField from '../../components/SearchField';
import clearIcon from '../../res/images/close.svg';
import Authentication from '../../modules/Authentication';
import { Duration } from 'luxon';
import Radix64 from '../../modules/Radix64';
import { AutoSizer, List } from 'react-virtualized';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../components/Button';
import { setOverviewMachines } from '../../store/actions/overview';

const useStyles = makeStyles({
    window: {},
    windowMax: {
        height: '100%',
    },
    windowFaults: {
        width: '100%',
    },
    closeRowIcon: {
        filter: hexColorToCSSFilter('#939FB9'),
        width: '24px',
    },
    closeRowIconRoot: {
        padding: '3px',
        height: '30px',
        width: '30px',
    },
    dialog: {
        margin: '0px !important',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        maxWidth: 'unset',
    },
    dialogMessage: {
        fontSize: '20px',
        textAlign: 'center',
        padding: '20px',
    },
    dialogHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#8090a8',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    dialogTitle: {
        textTransform: 'uppercase',
        color: '#ffffff',
        fontSize: '18px',
        flexGrow: 1,
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    dialogClose: {},
    dialogCloseIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    dialogButtonRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '10px',
        justifyContent: 'center',
        gap: '20px',
    },
    dialogConfirm: {
        marginTop: '15px',
        width: '150px',
        alignSelf: 'center',
        marginBottom: '10px',
    },
    dialogContent: {
        background: '#FFFFFF',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    dialogRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '10px',
    },
    content: {
        backgroundColor: '#f2f5f9',
        height: '400px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '10px',
        paddingRight: '10px',
        overflowX: 'hidden',
    },
    contentMax: {
        height: '100%',
    },
    tooltip: {
        borderRadius: '6px',
        borderColor: '#000000',
        borderWidth: '1px',
        fontSize: '14px',
        color: '#000000',
        opacity: '1 !important',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    eventsList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '10px',
    },
    eventsRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
    },
    tableCellEvents: {
        cursor: 'pointer',
    },
    events: {
        display: 'flex',
        flexDirection: 'column',
    },
    eventTimestamp: {
        fontSize: 'smaller',
        color: 'gray',
    },
    duration: {
        display: 'flex',
        flexDirection: 'column',
    },
    duration_subtitle: {
        textAlign: 'center',
        fontSize: 'smaller',
    },
    searchField: {
        background: '#ffffff',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        height: '40px',
        width: '300px',
    },
    searchFieldInput: {
        color: '#000000',
    },
    searchFieldIcon: {
        filter: hexColorToCSSFilter('#7E8CAB'),
    },
    iconButton: {
        width: '30px',
        height: '30px',
    },
    filterBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',
        marginLeft: '10px',
    },
    cleanStatus: {
        width: '30px',
    },
    timestamp: {
        display: 'flex',
        flexDirection: 'column',
    },
    openMachineIcon: {
        padding: '8px',
        filter: hexColorToCSSFilter('#818FA8'),
    },
    timestampInner: {
        margin: '0px',
        marginBottom: '5px',
    },
    table: {
        marginTop: '5px',
        display: 'grid',
        gridTemplateColumns: '3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        gap: '0px',
    },
    tableHeader: {
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center',
    },
    tableHeaderCenter: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableHeaderNoSort: {
        cursor: 'default',
    },
    tableCellWarning: {
        backgroundColor: '#F9E0E0',
    },
    tableCell: {
        borderBottomWidth: '1px',
        borderBottomColor: 'rgb(209 213 219)',
        paddingBottom: '2px',
        paddingTop: '2px',
        borderBottomStyle: 'solid',
        wordWrap: 'break-word',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    tableCellCenter: {
        textAlign: 'center',
        justifyContent: 'center',
    },
    tableHeaderRow: {},
    tableFaults: {
        gridTemplateColumns: '0.35fr 3fr 1fr 1fr 1.5fr 1.5fr 1fr 4fr 1.5fr',
    },
    tableContent: {
        height: '100%',
        width: '100%',
    },
    alert: {
        color: 'red',
    },
    leftIcon: {
        maxWidth: 30,
        maxHeight: 30,
        padding: 0,
    },
    buttonNoPadding: {
        padding: 10,
    },
    topWindowBar: {
        display: 'flex',
        flexDirection: 'row',
    },
});

const gApiClient = Authentication.getAPIClient();

const MAX_EXPANSION_SIZE = 2;
const DEFAULT_EXPANSION_SIZE = 1;

/** Machines dashboard inner window - showing client/site/cluster/machine view,
 * grouped by status and error state */
export default function MachinesTables(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isDownloadingLogs, setIsDownloadingLogs] = useState(null);
    const [downloadingLogsMessage, setDownloadingLogsMessage] = useState(null);
    const [isHiding, setIsHiding] = useState(false);
    const [machineToHideFromFaults, setMachineToHideFromFaults] = useState(
        null
    );
    const [sortAscending, setSortAscending] = useState(true);
    const showFaults = props.faults;
    const [expansionSize, setExpansionSize] = useState(
        showFaults ? MAX_EXPANSION_SIZE : DEFAULT_EXPANSION_SIZE
    );
    const [sortColumn, setSortColumn] = useState(
        showFaults ? 'duration' : 'location'
    );
    const [machineSearch, setMachineSearch] = useState('');
    const [eventTypes, setEventTypes] = useState({});
    const overviewMachines = useSelector(
        (state) => state.overview.overviewMachines
    );
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [popoverMachine, setPopoverMachine] = useState(null);
    const isPopoverOpen = Boolean(popoverAnchor);
    const filter = useSelector((state) => state.overview.filter);

    useEffect(() => {
        // Get mapping between event type and description
        gApiClient
            .callApi('admin/getEventTypes', 'GET', {}, {})
            .then((response) => {
                setEventTypes(response.data);
            });
    }, []);

    const hideMachineFromFaultsTable = async () => {
        const newOverviewMachines = [...overviewMachines];
        const newMachine = { ...machineToHideFromFaults, fault: null };

        setMachineToHideFromFaults(null);
        setIsHiding(true);

        try {
            await gApiClient.callApi(
                'removeOverviewMachineFromFaults',
                'POST',
                {},
                {
                    machine_id: newMachine._id,
                }
            );
        } catch (e) {
            console.error(e);
            setIsHiding(false);
            return;
        }

        setIsHiding(false);

        // Replace the machine (from machineToHideFromFaults) with the updated one
        const index = newOverviewMachines.findIndex(
            (x) => x._id === machineToHideFromFaults._id
        );
        newOverviewMachines[index] = newMachine;
        dispatch(setOverviewMachines(newOverviewMachines));
    };

    const renderSortIcon = (column) => {
        if (column !== sortColumn) {
            return null;
        }

        return <img src={sortAscending ? arrowUp : arrowDown} />;
    };

    const changeSortColumn = (column) => {
        if (column === sortColumn) {
            setSortAscending(!sortAscending);
        } else {
            setSortAscending(true);
            setSortColumn(column);
        }
    };

    const sortedAndFilteredMachines = useMemo(() => {
        let sortFunction = null;
        if (sortColumn === 'location') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.location.localeCompare(b.location)
                    : b.location.localeCompare(a.location);
            };
        } else if (sortColumn === 'alias') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.alias.localeCompare(b.alias.trim())
                    : b.alias.localeCompare(a.alias.trim());
            };
        } else if (sortColumn === 'fw_version') {
            sortFunction = (a, b) => {
                const fwVersion1 = a.fw_version;
                const fwVersion2 = b.fw_version;

                if (!fwVersion1 && fwVersion2) {
                    return 1;
                } else if (fwVersion1 && !fwVersion2) {
                    return -1;
                } else if (!fwVersion1 && !fwVersion2) {
                    return 0;
                }

                return sortAscending
                    ? a.fw_version.localeCompare(b.fw_version.trim())
                    : b.fw_version.localeCompare(a.fw_version.trim());
            };
        } else if (sortColumn === 'status') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.status.title.localeCompare(b.status.title)
                    : b.status.title.localeCompare(a.status.title);
            };
        } else if (sortColumn === 'consumption') {
            sortFunction = (a, b) => {
                const factor = sortAscending ? 1 : -1;
                return (
                    (a.capsule_consumption.monthly -
                        b.capsule_consumption.monthly) *
                    factor
                );
            };
        } else if (sortColumn === 'last_clean') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.last_clean.timestamp - b.last_clean.timestamp
                    : b.last_clean.timestamp - a.last_clean.timestamp;
            };
        } else if (sortColumn === 'clean_status') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.clean_status - b.clean_status
                    : b.clean_status - a.clean_status;
            };
        } else if (sortColumn === 'last_contact_time') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.last_contact_time - b.last_contact_time
                    : b.last_contact_time - a.last_contact_time;
            };
        } else if (sortColumn === 'last_food') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.last_food.timestamp - b.last_food.timestamp
                    : b.last_food.timestamp - a.last_food.timestamp;
            };
        } else if (sortColumn === 'fault') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.fault.title.localeCompare(b.fault.title)
                    : b.fault.title.localeCompare(a.fault.title);
            };
        } else if (sortColumn === 'duration') {
            sortFunction = (a, b) => {
                return sortAscending
                    ? a.fault.duration - b.fault.duration
                    : b.fault.duration - a.fault.duration;
            };
        }

        const selectedMachineIds = filter.selectedMachines.map((x) => x._id);
        let filteredMachines = overviewMachines
            .filter((x) => selectedMachineIds.includes(x._id))
            .map((x) => {
                let matchedGroups = STATUS_GROUPS.filter((s) =>
                    s.checkMachine(x)
                );
                const worstStatus = matchedGroups.sort(
                    (a, b) => b.priority - a.priority
                );

                return {
                    ...x,
                    location: `${x.client_name} / ${x.site_name}`,
                    status: worstStatus[0],
                };
            });

        if (showFaults) {
            // Only show machines with faults
            filteredMachines = filteredMachines.filter((x) => x.fault);
        }

        if (sortFunction) {
            filteredMachines = filteredMachines.sort(sortFunction);
        }

        if (machineSearch && machineSearch.trim().length > 0) {
            const search = machineSearch.trim().toLowerCase();
            filteredMachines = filteredMachines.filter((x) => {
                return (
                    x.alias?.toLowerCase().includes(search) ||
                    x.location?.toLowerCase().includes(search)
                );
            });
        }

        return filteredMachines;
    }, [
        filter.selectedMachines,
        machineSearch,
        overviewMachines,
        showFaults,
        sortAscending,
        sortColumn,
    ]);

    const renderTimestamp = (ts, isOld) => {
        if (!ts) {
            return <></>;
        }
        const parsedTs = DateTime.fromMillis(ts).toUTC();
        const nowUTC = DateTime.utc();
        const daysAgo = Math.round(parsedTs.diff(nowUTC, 'days').negate().days);
        let days;

        if (daysAgo === 0) {
            days = 'Today';
        } else if (daysAgo === 1) {
            days = '1 day ago';
        } else {
            days = `${daysAgo} days ago`;
        }

        return (
            <div className={classes.timestamp}>
                <p className={classes.timestampInner}>
                    {parsedTs.toFormat('MMM dd HH:mm')}
                </p>
                <p
                    className={clsx(
                        classes.timestampInner,
                        isOld ? classes.alert : null
                    )}
                >
                    {days}
                </p>
            </div>
        );
    };

    const onOpenMachine = (machineId) => {
        // Open single machine view for the selected machine ID (in a new tab)
        window.open(`/machines/${machineId}/dashboard`, '_blank');
    };

    const downloadLogs = async (machine) => {
        const machineId = machine._id;
        console.log('MACHINE ID', machineId);
        setIsDownloadingLogs(machineId);

        const startDate = DateTime.local().startOf('day');
        const endDate = startDate.plus({ days: 1 });

        try {
            await gApiClient.callApi(
                'admin/generateCSVFile',
                'POST',
                {},
                {
                    machine_ids: machineId,
                    // Also account for local timezone
                    start_date: startDate
                        .minus({ minutes: new Date().getTimezoneOffset() })
                        .toMillis(),
                    end_date: endDate
                        .minus({ minutes: new Date().getTimezoneOffset() })
                        .toMillis(),
                    minute_offset: -new Date().getTimezoneOffset(),
                    raw_logs_only: 0,
                    summary_report: 0,
                    reduced_report: 1,
                    csv_only: 0,
                }
            );

            setDownloadingLogsMessage(
                `Logs for ${machine.alias} for today will be sent to your email`
            );
        } catch (e) {
            console.error(e);
            const message = e.response?.data?.Message || e.message;
            setDownloadingLogsMessage(message);
        }

        setIsDownloadingLogs(null);
    };

    const hideEvents = () => {
        setPopoverMachine(null);
        setPopoverAnchor(null);
    };

    const rowRenderer = ({ index, key, style }) => {
        const machine = sortedAndFilteredMachines[index];
        const showEvents = (e) => {
            setPopoverMachine(machine);
            setPopoverAnchor(e.currentTarget);
        };

        return (
            <div
                key={key}
                style={style}
                className={clsx(
                    classes.table,
                    showFaults ? classes.tableFaults : null
                )}
            >
                {showFaults ? (
                    <>
                        <div
                            className={clsx(
                                classes.tableCell,
                                machine.fault.critical &&
                                    classes.tableCellWarning
                            )}
                        >
                            <IconButton
                                onClick={() =>
                                    setMachineToHideFromFaults(machine)
                                }
                                size="small"
                                classes={{ root: classes.closeRowIconRoot }}
                            >
                                <img
                                    src={clearIcon}
                                    className={classes.closeRowIcon}
                                    alt={props.title}
                                />
                            </IconButton>
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                machine.fault.critical &&
                                    classes.tableCellWarning
                            )}
                        >
                            {machine.location}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter,
                                machine.fault.critical &&
                                    classes.tableCellWarning
                            )}
                        >
                            {machine.alias}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter,
                                machine.fault.critical &&
                                    classes.tableCellWarning
                            )}
                        >
                            {machine.fw_version}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter,
                                machine.fault.critical &&
                                    classes.tableCellWarning
                            )}
                        >
                            <img src={machine.status.icon} />
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                machine.fault.critical &&
                                    classes.tableCellWarning
                            )}
                        >
                            {machine.fault.title}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter,
                                machine.fault.critical &&
                                    classes.tableCellWarning
                            )}
                        >
                            <div className={classes.cleanStatus}>
                                {machine.fault.warning && (
                                    <img
                                        src={durationWarning}
                                        className={classes.cleanStatus}
                                    />
                                )}
                            </div>
                            <div className={classes.duration}>
                                <div>
                                    {Duration.fromMillis(
                                        machine.fault.duration
                                    ).toFormat('hh:mm')}
                                </div>
                                <div className={classes.duration_subtitle}>
                                    H&nbsp;&nbsp;&nbsp;M
                                </div>
                            </div>
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                machine.fault.critical &&
                                    classes.tableCellWarning,
                                machine.events.length > 0 &&
                                    classes.tableCellEvents
                            )}
                            onClick={showEvents}
                        >
                            {machine.events.length > 0 && (
                                <>
                                    <img src={arrowDown} />
                                    <div className={classes.events}>
                                        <div className={classes.eventTimestamp}>
                                            {DateTime.fromMillis(
                                                machine.events[0].timestamp
                                            ).toFormat('HH:mm:ss')}
                                        </div>
                                        <div>
                                            {
                                                eventTypes[
                                                    Radix64.decodeRadix64(
                                                        machine.events[0]
                                                            .event_type
                                                    )
                                                ]
                                            }
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter,
                                machine.fault.critical &&
                                    classes.tableCellWarning
                            )}
                        >
                            <IconButton
                                onClick={() => {
                                    onOpenMachine(machine._id);
                                }}
                                className={classes.openMachineIcon}
                            >
                                <img src={openMachineIcon} alt="Open Machine" />
                            </IconButton>
                            {isDownloadingLogs === machine._id ? (
                                <CircularProgress
                                    size={48}
                                    thickness={3}
                                    className={classes.openMachineIcon}
                                />
                            ) : (
                                <IconButton
                                    onClick={() => {
                                        downloadLogs(machine);
                                    }}
                                    className={classes.openMachineIcon}
                                >
                                    <img
                                        src={downloadIcon}
                                        alt="Download Logs"
                                    />
                                </IconButton>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={classes.tableCell}>
                            {machine.location}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter
                            )}
                        >
                            {machine.alias}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter
                            )}
                        >
                            <img src={machine.status.icon} />
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter
                            )}
                        >
                            {machine.capsule_consumption.daily} |{' '}
                            {machine.capsule_consumption.weekly} |{' '}
                            {machine.capsule_consumption.monthly}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter
                            )}
                        >
                            {renderTimestamp(
                                machine.last_clean?.timestamp,
                                machine.last_clean?.old
                            )}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter
                            )}
                        >
                            <img
                                className={classes.cleanStatus}
                                src={
                                    machine.clean_status
                                        ? cleanStatusOk
                                        : cleanStatusFail
                                }
                            />
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter
                            )}
                        >
                            {machine.status.title === 'Disconnected'
                                ? renderTimestamp(machine.last_contact_time)
                                : ''}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter
                            )}
                        >
                            {renderTimestamp(machine.last_food?.timestamp)}
                        </div>
                        <div
                            className={clsx(
                                classes.tableCell,
                                classes.tableCellCenter
                            )}
                        >
                            <IconButton
                                onClick={() => {
                                    onOpenMachine(machine._id);
                                }}
                                className={classes.openMachineIcon}
                            >
                                <img src={openMachineIcon} alt="Open Machine" />
                            </IconButton>
                            {isDownloadingLogs === machine._id ? (
                                <CircularProgress
                                    size={48}
                                    thickness={3}
                                    className={classes.openMachineIcon}
                                />
                            ) : (
                                <IconButton
                                    onClick={() => {
                                        downloadLogs(machine);
                                    }}
                                    className={classes.openMachineIcon}
                                >
                                    <img
                                        src={downloadIcon}
                                        alt="Download Logs"
                                    />
                                </IconButton>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    };

    const topBar = () => {
        return (
            <div className={classes.topWindowBar}>
                {!showFaults && (
                    <>
                        <IconButton
                            onClick={() => setExpansionSize(expansionSize - 1)}
                            disabled={expansionSize === 0}
                            size="large"
                            classes={{ root: classes.buttonNoPadding }}
                        >
                            <img
                                src={minimizeIcon}
                                className={classes.leftIcon}
                            />
                        </IconButton>
                        <IconButton
                            onClick={() => setExpansionSize(expansionSize + 1)}
                            disabled={expansionSize === MAX_EXPANSION_SIZE}
                            size="large"
                            classes={{ root: classes.buttonNoPadding }}
                        >
                            <img
                                src={maximizeIcon}
                                className={classes.leftIcon}
                            />
                        </IconButton>
                    </>
                )}
                <div className={classes.filterBar}>
                    <SearchField
                        className={classes.search}
                        placeholder="Search Client/Site or Machine"
                        onSearch={setMachineSearch}
                        value={machineSearch}
                        backgroundClass={classes.searchField}
                        inputClass={classes.searchFieldInput}
                        iconClass={classes.searchFieldIcon}
                    />
                </div>
            </div>
        );
    };

    return (
        <InnerWindow
            loading={props.loading || isHiding}
            title={showFaults ? 'Faults' : 'Machines Status'}
            leftView={topBar()}
            className={clsx(
                classes.window,
                props.className,
                showFaults && classes.windowFaults,
                expansionSize === MAX_EXPANSION_SIZE && classes.windowMax
            )}
        >
            {expansionSize > 0 && (
                <div
                    className={clsx(
                        classes.content,
                        expansionSize === MAX_EXPANSION_SIZE &&
                            classes.contentMax
                    )}
                >
                    <div
                        className={clsx(
                            classes.table,
                            classes.tableHeaderRow,
                            showFaults ? classes.tableFaults : null
                        )}
                    >
                        {showFaults ? (
                            <>
                                <div className={classes.tableHeader}></div>
                                <div
                                    className={classes.tableHeader}
                                    onClick={() => changeSortColumn('location')}
                                >
                                    Client / Site {renderSortIcon('location')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() => changeSortColumn('alias')}
                                >
                                    Machine {renderSortIcon('alias')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() =>
                                        changeSortColumn('fw_version')
                                    }
                                >
                                    FW Version {renderSortIcon('fw_version')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() => changeSortColumn('status')}
                                >
                                    Machine Status {renderSortIcon('status')}
                                </div>
                                <div
                                    className={classes.tableHeader}
                                    onClick={() => changeSortColumn('fault')}
                                >
                                    Fault {renderSortIcon('fault')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() => changeSortColumn('duration')}
                                >
                                    Duration {renderSortIcon('duration')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderNoSort
                                    )}
                                >
                                    Events
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter,
                                        classes.tableHeaderNoSort
                                    )}
                                >
                                    Links
                                    <br />
                                    Machine Page | Logs
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className={classes.tableHeader}
                                    onClick={() => changeSortColumn('location')}
                                >
                                    Client / Site {renderSortIcon('location')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() => changeSortColumn('alias')}
                                >
                                    Machine {renderSortIcon('alias')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() => changeSortColumn('status')}
                                >
                                    Machine Status {renderSortIcon('status')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() =>
                                        changeSortColumn('consumption')
                                    }
                                >
                                    Consumption
                                    <br />D | W | M
                                    {renderSortIcon('consumption')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() =>
                                        changeSortColumn('last_clean')
                                    }
                                >
                                    Last Clean {renderSortIcon('last_clean')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() =>
                                        changeSortColumn('clean_status')
                                    }
                                >
                                    Clean Status{' '}
                                    {renderSortIcon('clean_status')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() =>
                                        changeSortColumn('last_contact_time')
                                    }
                                >
                                    Last Contact{' '}
                                    {renderSortIcon('last_contact_time')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderCenter
                                    )}
                                    onClick={() =>
                                        changeSortColumn('last_food')
                                    }
                                >
                                    Last Capsule {renderSortIcon('last_food')}
                                </div>
                                <div
                                    className={clsx(
                                        classes.tableHeader,
                                        classes.tableHeaderNoSort
                                    )}
                                >
                                    Links
                                    <br />
                                    Machine Page | Logs
                                </div>
                            </>
                        )}
                    </div>

                    <div className={clsx(classes.tableContent)}>
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                        key={sortedAndFilteredMachines.length}
                                        width={width}
                                        height={height}
                                        rowCount={
                                            sortedAndFilteredMachines.length
                                        }
                                        rowHeight={55}
                                        rowRenderer={rowRenderer}
                                        overscanRowCount={5}
                                    />
                                );
                            }}
                        </AutoSizer>
                    </div>
                    <Popover
                        anchorEl={popoverAnchor}
                        open={isPopoverOpen}
                        onClose={hideEvents}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        className={classes.tooltip}
                    >
                        <div className={classes.eventsList}>
                            {popoverMachine &&
                                popoverMachine.events.map((e) => (
                                    <div
                                        key={e.timestamp}
                                        className={classes.eventsRow}
                                    >
                                        <div className={classes.eventTimestamp}>
                                            {DateTime.fromMillis(
                                                e.timestamp
                                            ).toFormat('HH:mm:ss')}
                                        </div>
                                        <div>
                                            {
                                                eventTypes[
                                                    Radix64.decodeRadix64(
                                                        e.event_type
                                                    )
                                                ]
                                            }
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Popover>
                </div>
            )}
            <Dialog
                onClose={() => setDownloadingLogsMessage(null)}
                open={Boolean(downloadingLogsMessage)}
                style={{ position: 'absolute' }}
                BackdropProps={{ style: { position: 'absolute' } }}
                classes={{ paper: classes.dialog }}
            >
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogTitle}>Download Logs</div>
                </div>
                <div className={classes.dialogMessage}>
                    {downloadingLogsMessage}
                </div>
                <Button
                    className={classes.dialogConfirm}
                    label="Close"
                    onClick={() => setDownloadingLogsMessage(null)}
                />
            </Dialog>
            <Dialog
                onClose={() => setMachineToHideFromFaults(null)}
                open={Boolean(machineToHideFromFaults)}
                style={{ position: 'absolute' }}
                BackdropProps={{ style: { position: 'absolute' } }}
                classes={{ paper: classes.dialog }}
            >
                <div className={classes.dialogHeader}>
                    <div className={classes.dialogTitle}>
                        Hide machine from Faults table
                    </div>
                </div>
                <div className={classes.dialogMessage}>
                    Are you sure you want to hide machine{' '}
                    <b>{machineToHideFromFaults?.alias}</b> from the Faults
                    table?
                </div>
                <div className={classes.dialogButtonRow}>
                    <Button
                        className={classes.dialogConfirm}
                        label="Yes"
                        onClick={hideMachineFromFaultsTable}
                    />
                    <Button
                        className={classes.dialogConfirm}
                        label="No"
                        onClick={() => setMachineToHideFromFaults(false)}
                    />
                </div>
            </Dialog>
        </InnerWindow>
    );
}
