import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Collapse } from '@material-ui/core';
import expandedIcon from '../res/images/three_dots_vertical.svg';
import collapsedIcon from '../res/images/three_dots_horizontal.svg';
import levelIcon from '../res/images/chevron_right.svg';
import LoadingOverlay from './LoadingOverlay';
import { filterAllItems, ID_ALL_CLIENTS } from '../modules/MachineUtils';

const useStyles = makeStyles({
    listContainer: {
        overflowY: 'auto',
        transform: 'scaleX(-1)',
        height: 0,
        position: 'relative',
    },
    list: {
        transform: 'scaleX(-1)',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    divider: {
        backgroundColor: '#bec7d4',
        height: '1px',
        position: 'absolute',
        bottom: 0,
        left: '14px',
        right: '14px',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '66px',
    },
    listItemRootNoPadding: {
        padding: '0px',
    },
    itemLabel: {
        fontSize: '12px',
        color: '#ffffff',
        flexGrow: 1,
    },
    levelIcon: {
        marginRight: '1px',
        '&:last-of-type': {
            marginRight: '10px',
        },
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    expandIconContainer: {
        flexGrow: 1,
        marginRight: '10px',
    },
    expandIcon: {
        padding: '6px',
    },
});

function setItemExpanded(item) {
    if (!item.children) return;

    item.children.forEach((x) => {
        x.expanded = true;
        setItemExpanded(x);
    });
}

/** UI base for all list item types (client/site/cluster/machine) */
function BaseListItem(props) {
    const classes = useStyles();

    const item = props.item;
    const level = props.level || 0;
    let expendable = item.children && item.children.length > 0;
    const isMachine = item.previous_clusters;

    if (expendable && props.childrenFilter) {
        if (props.childrenFilter(item)) expendable = false;
    }

    const [expanded, setExpanded] = useState(false);

    if (!item.visible) {
        // This item doesn't match - don't show it
        return null;
    }

    const onExpand = () => {
        item.expanded = !item.expanded;
        setExpanded(!expanded);

        if (item.children && item.expanded && props.collapseAllTheWay) {
            setItemExpanded(item);
        }
    };

    if (props.customListItem) {
        return (
            <div>
                <ListItem
                    button={expendable}
                    className={clsx(classes.listItem, props.listItemClass)}
                    style={props.style}
                    onClick={expendable ? onExpand : null}
                    disableGutters={true}
                    classes={{ root: classes.listItemRootNoPadding }}
                >
                    {props.customListItem({
                        item: item,
                        level: level,
                    })}
                </ListItem>
                {expendable && (
                    <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            {item.children.map((m) => (
                                <BaseListItem
                                    item={m}
                                    key={m._id}
                                    level={level + 1}
                                    customListItem={props.customListItem}
                                    childrenFilter={props.childrenFilter}
                                    listItemClass={props.listItemClass}
                                />
                            ))}
                        </List>
                    </Collapse>
                )}
            </div>
        );
    }

    return (
        <div>
            <ListItem
                button={expendable}
                className={clsx(classes.listItem, props.listItemClass)}
                style={props.style}
                onClick={expendable ? onExpand : null}
            >
                {[...Array(level)].map((e, i) => (
                    <img
                        src={levelIcon}
                        key={i}
                        alt="level"
                        className={clsx(
                            classes.levelIcon,
                            props.colors
                                ? props.colors.itemLevelIconClass
                                : null
                        )}
                    />
                ))}

                <div
                    className={classes.itemLabel}
                    style={
                        props.colors && props.colors.labelColor
                            ? { color: props.colors.labelColor }
                            : null
                    }
                >
                    {isMachine ? item.alias : item.name}
                </div>

                <div className={classes.itemRightSide}>
                    <div className={classes.expandIconContainer}>
                        {expendable && !props.hideExpansionMarks && (
                            <img
                                className={
                                    props.colors
                                        ? props.colors.expansionIconClass
                                        : null
                                }
                                src={
                                    item.expanded ? expandedIcon : collapsedIcon
                                }
                                alt={item.expanded ? 'Collapse' : 'Expand'}
                            />
                        )}
                    </div>
                    {props.renderRightPart ? props.renderRightPart(item) : null}
                </div>
                <div className={classes.divider} />
            </ListItem>
            {expendable && (
                <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {item.children.map((m) => (
                            <GenericListItem
                                item={m}
                                key={m._id}
                                level={level + 1}
                                renderRightPart={props.renderRightPart}
                                colors={props.colors}
                                hideExpansionMarks={props.hideExpansionMarks}
                                customListItem={props.customListItem}
                                listItemClass={props.listItemClass}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </div>
    );
}

/** Represents a single list item (client/site/cluster/machine) */
function GenericListItem(props) {
    const item = props.item;

    if (!item.visible) {
        // This item doesn't match - don't show it
        return null;
    }

    const bgLevelColors = [
        '#00B09E',
        '#00988E',
        '#007F7E',
        '#006069',
        '#003d46',
    ];

    return (
        <BaseListItem
            item={props.item}
            style={{
                backgroundColor:
                    props.colors && props.colors.backgroundColors
                        ? props.colors.backgroundColors[props.level]
                        : bgLevelColors[props.level],
            }}
            level={props.level}
            renderRightPart={props.renderRightPart}
            colors={props.colors}
            hideExpansionMarks={props.hideExpansionMarks}
            customListItem={props.customListItem}
            childrenFilter={props.childrenFilter}
            listItemClass={props.listItemClass}
        />
    );
}

/** An expandable list showing a client/site/cluster/machine hierarchy */
export default function MachineList(props) {
    const classes = useStyles();

    // Clients list, where is client contains children (= sites), and each site contains clusters, and then machines.
    let [items, setItems] = useState([...props.items]);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const changeExpansion =
            (props.items.length !== items.length && items.length > 0) ||
            (props.filter && props.filter.length > 0);

        const newItems = filterAllItems(
            props.items,
            props.filter,
            props.countries,
            props.regions,
            props.clientTypes,
            props.categories,
            props.models,
            props.hideNonMarked,
            changeExpansion
        );
        setItems(newItems);
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.filter,
        props.items,
        props.countries,
        props.regions,
        props.clientTypes,
        props.categories,
        props.hideNonMarked,
        props.models,
    ]);

    return (
        <div className={clsx(classes.listContainer, props.className)}>
            {!loading && (
                <List className={classes.list}>
                    {items.map((m) =>
                        props.customListItem ? (
                            <BaseListItem
                                item={m}
                                key={m._id}
                                level={0}
                                customListItem={props.customListItem}
                                childrenFilter={props.childrenFilter}
                                listItemClass={props.listItemClass}
                                collapseAllTheWay={props.collapseAllTheWay}
                            />
                        ) : (
                            <GenericListItem
                                item={m}
                                key={m._id}
                                level={0}
                                renderRightPart={props.renderRightPart}
                                colors={props.colors}
                                hideExpansionMarks={props.hideExpansionMarks}
                                customListItem={props.customListItem}
                                childrenFilter={props.childrenFilter}
                                listItemClass={props.listItemClass}
                                collapseAllTheWay={props.collapseAllTheWay}
                            />
                        )
                    )}
                </List>
            )}
            <LoadingOverlay loading={loading} />
        </div>
    );
}
