import React, { useState } from 'react';
import filterIcon from '../../res/images/filter_small.svg';
import eyes from '../../res/images/eye.svg';
import faults from '../../res/images/duration_warning.svg';
import cogs from '../../res/images/cogs.svg';
import {
    TOGGLE_BAR_STATE_DASHBOARD,
    TOGGLE_BAR_STATE_FAULTS,
    TOGGLE_BAR_STATE_FILTER,
    TOGGLE_BAR_STATE_KPIS,
} from '../../store/actions/ui';
import ToggleBar from '../../components/ToggleBar';
import { makeStyles } from '@material-ui/core/styles';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import { useSelector } from 'react-redux';
import Button from '../../components/Button';
import Authentication from '../../modules/Authentication';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    button: {
        width: '150px',
        borderRadius: '32px',
        '& div': {
            //fontSize: '20px',
        },
        marginLeft: '10px',
    },
    filtersApplied: {
        marginLeft: '35px',
        backgroundColor: '#f4af00',
        borderRadius: '16.5px',
        border: 'solid 1px #000000',
        height: '33px',
        width: 'auto',
        paddingRight: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filtersAppliedIconContainer: {
        height: '26px',
        width: '26px',
        backgroundColor: '#000000',
        marginRight: '13px',
        marginLeft: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '13px',
    },
    filtersAppliedIcon: {
        height: '15px',
        width: '15px',
        filter: hexColorToCSSFilter('#f4af00'),
    },
    filtersAppliedText: {
        textTransform: 'uppercase',
        color: '#000000',
    },
});

const gApiClient = Authentication.getAPIClient();

/** Machines view specific toggle bar */
export default function MachinesToggleBar() {
    const classes = useStyles();
    const filter = useSelector((state) => state.overview.filter);
    const [isSavingFilter, setIsSavingFilter] = useState(false);
    const hasClientCategoriesSelected =
        filter.filterClientCategories &&
        Object.keys(filter.filterClientCategories).some((category) =>
            Object.keys(filter.filterClientCategories[category].values).some(
                (value) =>
                    filter.filterClientCategories[category].values[value]
                        .selected
            )
        );

    const saveFilter = async () => {
        setIsSavingFilter(true);

        await gApiClient.callApi(
            'setOverviewFilter',
            'POST',
            {},
            {
                selected_machines: filter.selectedMachines.map((x) => x._id),
                selected_countries: [...filter.selectedCountries],
                selected_regions: [...filter.selectedRegions],
                filter_client_categories: filter.filterClientCategories,
            }
        );

        setIsSavingFilter(false);
    };

    const buttons = [
        {
            title: 'Dashboard',
            value: TOGGLE_BAR_STATE_DASHBOARD,
            icon: eyes,
            url: '/overview',
        },
        {
            title: 'Faults',
            value: TOGGLE_BAR_STATE_FAULTS,
            icon: faults,
            url: '/overview/faults',
        },
        {
            title: 'Filter',
            value: TOGGLE_BAR_STATE_FILTER,
            icon: filterIcon,
            url: '/overview/filter',
        },
        /*{
            title: 'KPIs',
            value: TOGGLE_BAR_STATE_KPIS,
            icon: cogs,
            url: '/overview/kpis',
        },*/
    ];

    return (
        <div className={classes.container}>
            <ToggleBar buttons={buttons} />
            {!filter.allMachinesSelected && filter.selectedMachines.length > 0 && (
                <div className={classes.filtersApplied}>
                    <div className={classes.filtersAppliedIconContainer}>
                        <img
                            className={classes.filtersAppliedIcon}
                            src={filterIcon}
                            alt={'Filters Applied'}
                        />
                    </div>
                    <div className={classes.filtersAppliedText}>
                        Filter applied:{' '}
                        <b>Machines ({filter.selectedMachines.length})</b>
                    </div>
                </div>
            )}
            {filter.allMachinesSelected &&
                (filter.selectedCountries.size > 0 ||
                    filter.selectedRegions.size > 0 ||
                    hasClientCategoriesSelected) && (
                    <div className={classes.filtersApplied}>
                        <div className={classes.filtersAppliedIconContainer}>
                            <img
                                className={classes.filtersAppliedIcon}
                                src={filterIcon}
                                alt={'Filters Applied'}
                            />
                        </div>
                        <div className={classes.filtersAppliedText}>
                            Filter applied:{' '}
                            {filter.selectedCountries.size > 0 && (
                                <>
                                    Countries:{' '}
                                    <b>
                                        {[...filter.selectedCountries]
                                            .map(
                                                (c) =>
                                                    filter.countries.find(
                                                        (x) => x._id === c
                                                    ).name
                                            )
                                            .join(', ')}
                                    </b>
                                </>
                            )}
                            {filter.selectedRegions.size > 0 && (
                                <>
                                    {' '}
                                    Regions:{' '}
                                    <b>
                                        {[...filter.selectedRegions]
                                            .map(
                                                (c) =>
                                                    filter.regions.find(
                                                        (x) => x._id === c
                                                    ).name
                                            )
                                            .join(', ')}
                                    </b>
                                </>
                            )}
                            {hasClientCategoriesSelected && (
                                <>
                                    {' '}
                                    Client categories:{' '}
                                    <b>
                                        {Object.keys(
                                            filter.filterClientCategories
                                        )
                                            .filter((category) =>
                                                Object.keys(
                                                    filter
                                                        .filterClientCategories[
                                                        category
                                                    ].values
                                                ).some(
                                                    (value) =>
                                                        filter
                                                            .filterClientCategories[
                                                            category
                                                        ].values[value].selected
                                                )
                                            )
                                            .map((category) =>
                                                Object.keys(
                                                    filter
                                                        .filterClientCategories[
                                                        category
                                                    ].values
                                                )
                                                    .filter(
                                                        (value) =>
                                                            filter
                                                                .filterClientCategories[
                                                                category
                                                            ].values[value]
                                                                .selected
                                                    )
                                                    .map(
                                                        (value) =>
                                                            filter
                                                                .filterClientCategories[
                                                                category
                                                            ].values[value]
                                                                .label
                                                    )
                                                    .join(', ')
                                            )
                                            .join(', ')}
                                    </b>
                                </>
                            )}
                        </div>
                    </div>
                )}
            <Button
                label="Save Filter"
                className={classes.button}
                loading={isSavingFilter}
                onClick={saveFilter}
            />
        </div>
    );
}
