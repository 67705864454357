import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import InnerWindow from '../../components/InnerWindow';
import closeIcon from '../../res/images/clear.png';
import clone from 'clone';
import upIcon from '../../res/images/up_circle.svg';
import filterIcon from '../../res/images/filter_circle_outline.svg';
import MachineList from '../../components/MachineList';
import { useFilterChanged } from '../../store/reducers/overview';
import { useDispatch, useSelector } from 'react-redux';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import machineIcon from '../../res/images/machine.svg';
import { IconButton, List, ListItem } from '@material-ui/core';
import openMachineIcon from '../../res/images/machine_circle_arrow.svg';
import {
    copyMachineListExpansion,
    filterAllItems,
    findAllMachines,
    findItem,
    findSelectedMachines,
    markItem,
    SORT_TYPE_ALPHABETICAL,
    SORT_TYPE_ALPHABETICAL_REVERSE,
    SORT_TYPE_MACHINES_HIGH_TO_LOW,
    SORT_TYPE_MACHINES_LOW_TO_HIGH,
    sortItems,
} from '../../modules/MachineUtils';
import ToggleButton from '../../components/ToggleButton';
import SearchField from '../../components/SearchField';
import locationIcon from '../../res/images/location_circle.svg';
import clientIcon from '../../res/images/store.svg';
import checkmarkIcon from '../../res/images/check_mark.svg';
import Button from '../../components/Button';
import { STATUS_GROUPS } from './StatusGroups';
import ReactTooltip from 'react-tooltip';
import randomColor from 'randomcolor';
import { setOverviewFilter } from '../../store/actions/overview';
import arrowDownIcon from '../../res/images/arrow_drop_down.svg';
import ExpansionPanel from '../../components/ExpansionPanel';
import eyeIcon from '../../res/images/eye_outline.svg';
import noEyeIcon from '../../res/images/no_eye_outline.svg';
import Checkbox from '../../components/Checkbox';

const useStyles = makeStyles({
    itemCheck: {
        marginLeft: 'auto',
    },
    flavourCategoryCheckIcon: {
        border: '1px solid #5A6C84',
    },
    itemCheckIcon: {
        backgroundColor: '#E1E6ED66',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    itemCheckedOnIcon: {
        backgroundColor: '#E1E6ED66',
        '&:before': {
            filter: hexColorToCSSFilter('#344269'),
        },
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    itemCheckCategoryIcon: {
        border: '1px solid #5B6885',
    },
    listSeparator: {
        width: '100%',
        height: '1px',
        backgroundColor: '#344269',
    },
    categoryValue: {
        backgroundColor: '#E1E5ED',
        color: '#314867',
        fontSize: '16px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
    },
    categoryValueRoot: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    categoryValueInner: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        paddingLeft: '16px',
        paddingRight: '8px',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    categoryValues: {
        width: '100%',
    },
    categoryContainer: {
        marginLeft: '15px !important',
        marginRight: '15px !important',
        marginBottom: '5px !important',
        border: '1px solid #314867',
        borderRadius: '4px',
    },
    categoryTitle: {
        color: '#314867',
        fontSize: '16px',
    },
    categoryTitleExpanded: {
        backgroundColor: '#C4CBD7',
        color: '#314867',
        borderRadius: '4px',
    },
    categoryExpanded: {
        borderRadius: '4px',
        padding: '0px',
    },
    categoryLeftIcon: {
        '& img': {
            filter: hexColorToCSSFilter('#314867'),
        },
        '&.Mui-expanded': {
            transform: 'rotate(180deg)',
            '& img': {
                filter: hexColorToCSSFilter('#314867'),
            },
        },
    },
    window: {},
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    filterContent: {
        backgroundColor: '#a1adc3',
        height: 'unset',
        paddingTop: '5px',
    },
    filterContainer: {
        overflowY: 'auto',
        flex: 1,
    },
    list: {
        width: '100%',
        flexGrow: 1,
    },
    itemLevelIcon: {
        filter: hexColorToCSSFilter('#7d90aa'),
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemMachineCount: {
        color: '#7d90aa',
        opacity: 0.8,
        fontSize: '16px',
        fontWeight: 300,
        marginLeft: '5px',
        width: '30px',
    },
    itemMachineIcon: {
        width: '21px',
        height: '21px',
        filter: hexColorToCSSFilter('#7d90aa'),
    },
    openMachineIcon: {
        padding: '8px',
    },
    openMachineIconImage: {
        filter: hexColorToCSSFilter('#7d90aa'),
        width: '31px',
        height: '31px',
    },
    itemStatusIcon: {
        width: '20px',
        height: '20px',
        marginRight: '20px',
    },
    machineItemStatusIcon: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
    },
    sortButtonsContainer: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#a1adc3',
    },
    sortButton: {
        marginRight: '10px',
        width: '80px',
        borderColor: '#ffffff',
        backgroundColor: '#a1adc3',
        color: '#ffffff',
        '&.Mui-selected': {
            borderColor: '#ffffff',
            backgroundColor: '#69727d',
            color: '#ffffff',
        },
    },
    sortButtonIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
        width: '19px',
        height: '19px',
        marginRight: '4px',
    },
    searchHeader: {
        background: '#f1f4fa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    search: {
        width: '368px',
        marginTop: '20px',
        marginBottom: '16px',
    },
    searchBackground: {
        backgroundColor: '#abb3c9',
    },
    searchIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    filterSection: {
        paddingTop: '10px',
        paddingLeft: '35px',
        paddingRight: '35px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterIcon: {
        width: '18px',
        height: '18px',
        marginRight: '10px',
        filter: hexColorToCSSFilter('#ffffff'),
        opacity: 0.6,
    },
    filterIconBigger: {
        width: '32px',
        height: '32px',
        marginRight: '3px',
    },
    filterLabel: {
        textTransform: 'uppercase',
        fontSize: '12px',
        color: '#ffffff',
    },
    filterButtonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        paddingTop: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        justifyContent: 'center',
    },
    filterButton: {
        width: '120px',
        marginBottom: '15px',
        textAlign: 'center',
    },
    separator: {
        marginBottom: '24px',
        marginTop: '14px',
        height: '1px',
        minHeight: '1px',
        backgroundColor: '#7d90aa',
    },
    closeButton: {
        width: '140px',
        alignSelf: 'center',
        marginTop: '50px',
        marginBottom: '20px',
    },
    machineStatusIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '7px',
    },
    tooltip: {
        borderRadius: '6px',
        fontSize: '14px',
        color: '#000000',
        backgroundColor: '#ffffff',
        opacity: '1 !important',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    machineCheckbox: {
        height: '24px',
        width: '24px',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        marginLeft: '20px',
        cursor: 'pointer',
    },
    machineCheckboxIcon: {
        height: '24px',
        width: '24px',
        filter: hexColorToCSSFilter('#8290b0'),
    },
});

const ID_ALL_MACHINES = 'ALL_MACHINES';

function createAllMachines(items) {
    return {
        _id: ID_ALL_MACHINES,
        name: 'All Machines',
        children: [],
        machineCount: items
            .map((c) => c.machineCount)
            .reduce((acc, val) => acc + val, 0),
    };
}

/** Initializes newly-selected machines with random colors */
export function initializeMachines(machines) {
    if (machines.length === 0) return;

    // Generate random colors for those new machines, and mark their visibility as true by default
    const colors = randomColor({
        count: machines.length,
        luminosity: 'dark',
    });
    machines.forEach((machine) => {
        machine.color = colors.pop();
        machine.kpiVisible = true; // Visible by default on the KPI graph
    });
}

/** Machine selection inner window - selecting machines for the overview filter,
 * with filtering / sorting capabilities. */
export default function MachineSelection(props) {
    const classes = useStyles();
    const [showFilter, setShowFilter] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [items, setItems] = useState([]);
    const filter = useSelector((state) => state.overview.filter);
    const [sortType, setSortType] = useState(SORT_TYPE_ALPHABETICAL);
    const [initializedCategories, setInitializedCategories] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState(
        filter.selectedCountries
    );
    const [selectedRegions, setSelectedRegions] = useState(
        filter.selectedRegions
    );
    const [clientCategories, setClientCategories] = useState(
        filter.filterClientCategories
    );
    const hasClientCategoriesSelected =
        clientCategories &&
        Object.keys(clientCategories).some((category) =>
            Object.keys(clientCategories[category].values).some(
                (value) => clientCategories[category].values[value].selected
            )
        );

    const dispatch = useDispatch();

    useEffect(() => {
        if (initializedCategories || !filter.filterClientCategories) return;
        setInitializedCategories(true);
        setClientCategories(filter.filterClientCategories);
    }, [filter.filterClientCategories, initializedCategories]);

    useEffect(() => {
        const newFilter = {
            selectedCountries: selectedCountries,
            selectedRegions: selectedRegions,
            filterClientCategories: clientCategories,
        };

        if (filter.allMachinesSelected) {
            const itemsCopy = clone(items);
            const newItems = filterAllItems(
                itemsCopy,
                '',
                selectedCountries,
                selectedRegions,
                null,
                clientCategories,
                null,
                false,
                false
            );
            const newSelectedMachines = findAllMachines(newItems).filter(
                (m) => m.visible
            );

            if (newSelectedMachines.length !== filter.selectedMachines.length) {
                newFilter.selectedMachines = newSelectedMachines;
            }
        }

        dispatch(setOverviewFilter(newFilter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedCountries,
        selectedRegions,
        filter.allMachinesSelected,
        items,
        dispatch,
        filter.selectedMachines,
        clientCategories,
        hasClientCategoriesSelected,
    ]);

    useEffect(() => {
        // Sort type has changed - update items
        if (items && items.length > 0) {
            const itemsCopy = clone(items);
            const allMachines = itemsCopy.shift(); // All machines item is always first
            sortItems(itemsCopy, sortType);
            itemsCopy.unshift(allMachines);

            setItems(itemsCopy);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortType]);

    const onOpenMachine = (machineId) => {
        // Open single machine view for the selected machine ID (in a new window)
        const win = window.open(`/machines/${machineId}/dashboard`, '_blank');
        win.focus();
    };

    const toggleItemSelected = (item, isMachine) => {
        const itemsCopy = clone(items);
        const itemCopy = findItem(itemsCopy, item._id);
        const newValue = !itemCopy.selected;
        let allMachinesSelected = false;

        if (newValue) {
            if (item._id !== ID_ALL_MACHINES) {
                // Another item was checked on - Uncheck all machines option
                itemsCopy[0].selected = false;
                allMachinesSelected = false;
            } else {
                // All machines chosen - unselect all other items
                allMachinesSelected = true;
                itemsCopy.slice(1).forEach((client) => markItem(client, false));
            }
        }

        // Select/unselect this item and all its children
        markItem(itemCopy, newValue);

        setItems(itemsCopy);

        // Calculate selected machines
        let selectedMachines = [];

        if (allMachinesSelected) {
            // All machines were selected
            selectedMachines = findAllMachines(itemsCopy.slice(1)).filter(
                (m) => m.visible
            );
        } else {
            // See which machines were selected
            selectedMachines = findSelectedMachines(itemsCopy.slice(1));
        }

        // Find all newly-selected machines
        const newMachines = [...selectedMachines];
        filter.selectedMachines.forEach((machine) => {
            const index = newMachines.findIndex((x) => x._id === machine._id);
            if (index > -1) {
                newMachines.splice(index, 1);
            }
        });

        initializeMachines(newMachines);

        // Update filter as well
        dispatch(
            setOverviewFilter({
                clients: itemsCopy.slice(1), // Don't include "All machines"
                allMachinesSelected: allMachinesSelected,
                selectedMachines: selectedMachines,
            })
        );
    };

    useFilterChanged(filter, () => {
        // Machine list - either changed from the Filters Applied window, or initially downloaded via API
        const itemsCopy = clone(filter.clients);
        sortItems(itemsCopy, sortType);

        // Maintain expansion of the machine tree view
        copyMachineListExpansion(items, itemsCopy);

        // Add the all machines item
        const allMachinesItem = createAllMachines(itemsCopy);
        allMachinesItem.selected = filter.allMachinesSelected;
        itemsCopy.unshift(allMachinesItem);

        setItems(itemsCopy);
    });

    const renderRightPart = (item) => {
        const isMachine = item.previous_clusters;
        let machineCount = item.machineCount;

        if (item._id === ID_ALL_MACHINES) {
            machineCount = items
                .slice(1)
                .filter((c) => c.visible)
                .map((c) => c.machineCount)
                .reduce((acc, val) => acc + val, 0);
        }

        if (!isMachine) {
            return (
                <div className={classes.itemRightSide}>
                    {item.icon && (
                        <img
                            src={item.icon}
                            className={classes.itemStatusIcon}
                            alt="Status"
                        />
                    )}
                    <img
                        src={machineIcon}
                        className={classes.itemMachineIcon}
                        alt="Machine Count"
                    />
                    <div className={classes.itemMachineCount}>
                        {machineCount}
                    </div>

                    <div
                        className={classes.machineCheckbox}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            toggleItemSelected(item, false);
                        }}
                    >
                        {item.selected && (
                            <img
                                className={classes.machineCheckboxIcon}
                                src={checkmarkIcon}
                                alt={'Select'}
                            />
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classes.itemRightSide}>
                    {item.icon && (
                        <img
                            src={item.icon}
                            className={classes.machineItemStatusIcon}
                            alt="Status"
                        />
                    )}

                    {[...item.statusGroups].map((s) => {
                        const status = STATUS_GROUPS.find(
                            (status) => status.title === s
                        );
                        return (
                            <img
                                key={s}
                                className={classes.machineStatusIcon}
                                src={status.icon}
                                alt={status.title}
                                data-tip={status.title}
                            />
                        );
                    })}

                    <IconButton
                        onClick={() => {
                            onOpenMachine(item._id);
                        }}
                        className={classes.openMachineIcon}
                    >
                        <img
                            src={openMachineIcon}
                            className={classes.openMachineIconImage}
                            alt="Open Machine"
                        />
                    </IconButton>

                    <div
                        className={classes.machineCheckbox}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            toggleItemSelected(item, true);
                        }}
                    >
                        {item.selected && (
                            <img
                                className={classes.machineCheckboxIcon}
                                src={checkmarkIcon}
                                alt={'Select Machine'}
                                style={
                                    item.color
                                        ? {
                                              filter: hexColorToCSSFilter(
                                                  item.color,
                                                  true
                                              ),
                                          }
                                        : null
                                }
                            />
                        )}
                    </div>
                </div>
            );
        }
    };

    const toggleSelectedCountry = (countryId) => {
        const selectedCountriesCopy = clone(selectedCountries);
        if (selectedCountriesCopy.has(countryId)) {
            selectedCountriesCopy.delete(countryId);
        } else {
            selectedCountriesCopy.add(countryId);
        }

        setSelectedCountries(selectedCountriesCopy);
    };

    const toggleSelectedRegion = (regionId) => {
        const selectedRegionsCopy = clone(selectedRegions);
        if (selectedRegionsCopy.has(regionId)) {
            selectedRegionsCopy.delete(regionId);
        } else {
            selectedRegionsCopy.add(regionId);
        }

        setSelectedRegions(selectedRegionsCopy);
    };

    const toggleSelectedCategory = (category, value, selected) => {
        const newCategories = clone(clientCategories);
        newCategories[category].values[value].selected = selected;

        setClientCategories(newCategories);
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={'Machine Selection'}
            loading={props.loading}
            icon={closeIcon}
            contentClassName={showFilter ? classes.filterContainer : null}
            onIconClick={() => {
                if (props.loading) return;

                // Reset selection - move to all machines selection
                if (!items[0].selected) {
                    toggleItemSelected(items[0]);
                }
                if (showFilter) {
                    setSelectedCountries(new Set());
                    setSelectedRegions(new Set());

                    const newCategories = clone(clientCategories);
                    Object.keys(newCategories).forEach((category) => {
                        Object.keys(newCategories[category].values).forEach(
                            (value) => {
                                newCategories[category].values[
                                    value
                                ].selected = false;
                            }
                        );
                    });
                    setClientCategories(newCategories);
                }
            }}
            leftIcon={showFilter ? upIcon : filterIcon}
            onLeftIconClick={() => {
                if (props.loading) return;
                setShowFilter(!showFilter);
            }}
        >
            {showFilter ? (
                <div className={clsx(classes.content, classes.filterContent)}>
                    <ExpansionPanel
                        className={classes.categoryContainer}
                        title={'Country'}
                        leftIcon={arrowDownIcon}
                        leftIconClass={classes.categoryLeftIcon}
                        expandedSummaryClass={classes.categoryTitleExpanded}
                        expansionSummaryClass={classes.categoryTitleExpanded}
                        titleClass={classes.categoryTitle}
                        expandedDetailsClass={classes.categoryExpanded}
                    >
                        <List className={classes.categoryValues} disablePadding>
                            {filter.countries.map((country, index) => (
                                <ListItem
                                    key={country._id}
                                    className={classes.categoryValue}
                                    disableGutters
                                    classes={{
                                        root: classes.categoryValueRoot,
                                    }}
                                >
                                    {index > 0 && (
                                        <div
                                            className={classes.listSeparator}
                                        />
                                    )}
                                    <div className={classes.categoryValueInner}>
                                        <div>{country.name}</div>
                                        <Checkbox
                                            checked={selectedCountries.has(
                                                country._id
                                            )}
                                            className={classes.itemCheck}
                                            iconClassName={
                                                classes.itemCheckIcon
                                            }
                                            checkedIconClassName={clsx(
                                                classes.itemCheckedOnIcon,
                                                classes.itemCheckIcon,
                                                classes.itemCheckCategoryIcon
                                            )}
                                            onChange={(v) => {
                                                toggleSelectedCountry(
                                                    country._id
                                                );
                                            }}
                                        />
                                    </div>
                                </ListItem>
                            ))}
                        </List>
                    </ExpansionPanel>

                    <ExpansionPanel
                        className={classes.categoryContainer}
                        title={'Region'}
                        leftIcon={arrowDownIcon}
                        leftIconClass={classes.categoryLeftIcon}
                        expandedSummaryClass={classes.categoryTitleExpanded}
                        expansionSummaryClass={classes.categoryTitleExpanded}
                        titleClass={classes.categoryTitle}
                        expandedDetailsClass={classes.categoryExpanded}
                    >
                        <div className={classes.filterButtonContainer}>
                            {filter.regions.map((region, index) => (
                                <ListItem
                                    key={region._id}
                                    className={classes.categoryValue}
                                    disableGutters
                                    classes={{
                                        root: classes.categoryValueRoot,
                                    }}
                                >
                                    {index > 0 && (
                                        <div
                                            className={classes.listSeparator}
                                        />
                                    )}
                                    <div className={classes.categoryValueInner}>
                                        <div>{region.name}</div>
                                        <Checkbox
                                            checked={selectedRegions.has(
                                                region._id
                                            )}
                                            className={classes.itemCheck}
                                            iconClassName={
                                                classes.itemCheckIcon
                                            }
                                            checkedIconClassName={clsx(
                                                classes.itemCheckedOnIcon,
                                                classes.itemCheckIcon,
                                                classes.itemCheckCategoryIcon
                                            )}
                                            onChange={(v) => {
                                                toggleSelectedRegion(
                                                    region._id
                                                );
                                            }}
                                        />
                                    </div>
                                </ListItem>
                            ))}
                        </div>
                    </ExpansionPanel>

                    {clientCategories.client_group && (
                        <ExpansionPanel
                            className={classes.categoryContainer}
                            title={'Client Group'}
                            leftIcon={arrowDownIcon}
                            leftIconClass={classes.categoryLeftIcon}
                            expandedSummaryClass={classes.categoryTitleExpanded}
                            expansionSummaryClass={
                                classes.categoryTitleExpanded
                            }
                            titleClass={classes.categoryTitle}
                            expandedDetailsClass={classes.categoryExpanded}
                        >
                            <div className={classes.filterButtonContainer}>
                                {Object.keys(
                                    clientCategories.client_group.values
                                )
                                    .sort((a, b) =>
                                        clientCategories.client_group.values[
                                            a
                                        ].label.localeCompare(
                                            clientCategories.client_group
                                                .values[b].label
                                        )
                                    )
                                    .map((value, index) => (
                                        <ListItem
                                            key={value}
                                            className={classes.categoryValue}
                                            disableGutters
                                            classes={{
                                                root: classes.categoryValueRoot,
                                            }}
                                        >
                                            {index > 0 && (
                                                <div
                                                    className={
                                                        classes.listSeparator
                                                    }
                                                />
                                            )}
                                            <div
                                                className={
                                                    classes.categoryValueInner
                                                }
                                            >
                                                <div>
                                                    {
                                                        clientCategories
                                                            .client_group
                                                            .values[value].label
                                                    }
                                                </div>
                                                <Checkbox
                                                    checked={
                                                        clientCategories
                                                            .client_group
                                                            .values[value]
                                                            .selected
                                                    }
                                                    className={
                                                        classes.itemCheck
                                                    }
                                                    iconClassName={
                                                        classes.itemCheckIcon
                                                    }
                                                    checkedIconClassName={clsx(
                                                        classes.itemCheckedOnIcon,
                                                        classes.itemCheckIcon,
                                                        classes.itemCheckCategoryIcon
                                                    )}
                                                    onChange={(v) => {
                                                        toggleSelectedCategory(
                                                            'client_group',
                                                            value,
                                                            !clientCategories
                                                                .client_group
                                                                .values[value]
                                                                .selected
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </ListItem>
                                    ))}
                            </div>
                        </ExpansionPanel>
                    )}

                    {clientCategories.channel_partner && (
                        <ExpansionPanel
                            className={classes.categoryContainer}
                            title={'Channel Partner'}
                            leftIcon={arrowDownIcon}
                            leftIconClass={classes.categoryLeftIcon}
                            expandedSummaryClass={classes.categoryTitleExpanded}
                            expansionSummaryClass={
                                classes.categoryTitleExpanded
                            }
                            titleClass={classes.categoryTitle}
                            expandedDetailsClass={classes.categoryExpanded}
                        >
                            <div className={classes.filterButtonContainer}>
                                {Object.keys(
                                    clientCategories.channel_partner.values
                                )
                                    .sort((a, b) =>
                                        clientCategories.channel_partner.values[
                                            a
                                        ].label.localeCompare(
                                            clientCategories.channel_partner
                                                .values[b].label
                                        )
                                    )
                                    .map((value, index) => (
                                        <ListItem
                                            key={value}
                                            className={classes.categoryValue}
                                            disableGutters
                                            classes={{
                                                root: classes.categoryValueRoot,
                                            }}
                                        >
                                            {index > 0 && (
                                                <div
                                                    className={
                                                        classes.listSeparator
                                                    }
                                                />
                                            )}
                                            <div
                                                className={
                                                    classes.categoryValueInner
                                                }
                                            >
                                                <div>
                                                    {
                                                        clientCategories
                                                            .channel_partner
                                                            .values[value].label
                                                    }
                                                </div>
                                                <Checkbox
                                                    checked={
                                                        clientCategories
                                                            .channel_partner
                                                            .values[value]
                                                            .selected
                                                    }
                                                    className={
                                                        classes.itemCheck
                                                    }
                                                    iconClassName={
                                                        classes.itemCheckIcon
                                                    }
                                                    checkedIconClassName={clsx(
                                                        classes.itemCheckedOnIcon,
                                                        classes.itemCheckIcon,
                                                        classes.itemCheckCategoryIcon
                                                    )}
                                                    onChange={(v) => {
                                                        toggleSelectedCategory(
                                                            'channel_partner',
                                                            value,
                                                            !clientCategories
                                                                .channel_partner
                                                                .values[value]
                                                                .selected
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </ListItem>
                                    ))}
                            </div>
                        </ExpansionPanel>
                    )}

                    {clientCategories.client_type && (
                        <ExpansionPanel
                            className={classes.categoryContainer}
                            title={'Client Type'}
                            leftIcon={arrowDownIcon}
                            leftIconClass={classes.categoryLeftIcon}
                            expandedSummaryClass={classes.categoryTitleExpanded}
                            expansionSummaryClass={
                                classes.categoryTitleExpanded
                            }
                            titleClass={classes.categoryTitle}
                            expandedDetailsClass={classes.categoryExpanded}
                        >
                            <div className={classes.filterButtonContainer}>
                                {Object.keys(
                                    clientCategories.client_type.values
                                )
                                    .sort((a, b) =>
                                        clientCategories.client_type.values[
                                            a
                                        ].label.localeCompare(
                                            clientCategories.client_type.values[
                                                b
                                            ].label
                                        )
                                    )
                                    .map((value, index) => (
                                        <ListItem
                                            key={value}
                                            className={classes.categoryValue}
                                            disableGutters
                                            classes={{
                                                root: classes.categoryValueRoot,
                                            }}
                                        >
                                            {index > 0 && (
                                                <div
                                                    className={
                                                        classes.listSeparator
                                                    }
                                                />
                                            )}
                                            <div
                                                className={
                                                    classes.categoryValueInner
                                                }
                                            >
                                                <div>
                                                    {
                                                        clientCategories
                                                            .client_type.values[
                                                            value
                                                        ].label
                                                    }
                                                </div>
                                                <Checkbox
                                                    checked={
                                                        clientCategories
                                                            .client_type.values[
                                                            value
                                                        ].selected
                                                    }
                                                    className={
                                                        classes.itemCheck
                                                    }
                                                    iconClassName={
                                                        classes.itemCheckIcon
                                                    }
                                                    checkedIconClassName={clsx(
                                                        classes.itemCheckedOnIcon,
                                                        classes.itemCheckIcon,
                                                        classes.itemCheckCategoryIcon
                                                    )}
                                                    onChange={(v) => {
                                                        toggleSelectedCategory(
                                                            'client_type',
                                                            value,
                                                            !clientCategories
                                                                .client_type
                                                                .values[value]
                                                                .selected
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </ListItem>
                                    ))}
                            </div>
                        </ExpansionPanel>
                    )}

                    <Button
                        className={classes.closeButton}
                        label="Close"
                        onClick={() => {
                            setShowFilter(false);
                        }}
                    />
                </div>
            ) : (
                <div className={classes.content}>
                    <div className={classes.sortButtonsContainer}>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() => setSortType(SORT_TYPE_ALPHABETICAL)}
                            selected={sortType === SORT_TYPE_ALPHABETICAL}
                        >
                            A-Z
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_ALPHABETICAL_REVERSE)
                            }
                            selected={
                                sortType === SORT_TYPE_ALPHABETICAL_REVERSE
                            }
                        >
                            Z-A
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_MACHINES_HIGH_TO_LOW)
                            }
                            selected={
                                sortType === SORT_TYPE_MACHINES_HIGH_TO_LOW
                            }
                        >
                            <img
                                className={classes.sortButtonIcon}
                                src={machineIcon}
                                alt="Machine Count"
                            />
                            High
                        </ToggleButton>
                        <ToggleButton
                            className={classes.sortButton}
                            onChange={() =>
                                setSortType(SORT_TYPE_MACHINES_LOW_TO_HIGH)
                            }
                            selected={
                                sortType === SORT_TYPE_MACHINES_LOW_TO_HIGH
                            }
                        >
                            <img
                                className={classes.sortButtonIcon}
                                src={machineIcon}
                                alt="Machine Count"
                            />
                            Low
                        </ToggleButton>
                    </div>
                    <div className={classes.searchHeader}>
                        <SearchField
                            className={classes.search}
                            placeholder="Search"
                            onSearch={setSearchText}
                            backgroundClass={classes.searchBackground}
                            iconClass={classes.searchIcon}
                        />
                    </div>
                    <MachineList
                        className={classes.list}
                        items={items}
                        filter={searchText}
                        countries={filter.selectedCountries}
                        regions={filter.selectedRegions}
                        categories={filter.filterClientCategories}
                        renderRightPart={renderRightPart}
                        colors={{
                            labelColor: '#7d90aa',
                            backgroundColors: [
                                '#f1f4f9',
                                '#e6ebf1',
                                '#dee4eb',
                                '#dee4eb',
                                '#cfd7e0',
                            ],
                            itemLevelIconClass: classes.itemLevelIcon,
                            expansionIconClass: classes.itemLevelIcon,
                        }}
                    />
                </div>
            )}

            <ReactTooltip
                border
                borderColor={'#000000'}
                place="top"
                type="light"
                effect="solid"
                className={classes.tooltip}
            />
        </InnerWindow>
    );
}
