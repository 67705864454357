import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import InnerWindow from '../../components/InnerWindow';
import closeIcon from '../../res/images/clear.png';
import removeFilterIcon from '../../res/images/close.svg';
import { useFilterChanged } from '../../store/reducers/overview';
import clone from 'clone';
import {
    calculateMachineCount,
    findAllMachines,
    findItem,
    formatClientTitle,
    markItem,
    unselectItem,
} from '../../modules/MachineUtils';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import {
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
} from '@material-ui/core';
import clientIcon from '../../res/images/store.svg';
import machineIcon from '../../res/images/machine.svg';
import collapsedIcon from '../../res/images/three_dots_horizontal.svg';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import openMachineIcon from '../../res/images/machine_circle_arrow.svg';
import { STATUS_GROUPS } from './StatusGroups';
import { setOverviewFilter } from '../../store/actions/overview';

const useStyles = makeStyles({
    window: {
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: 0,
    },
    expansionPanelContainer: {
        '& .MuiExpansionPanelDetails-root': {
            padding: '0px',
        },
        '&.Mui-expanded': {
            margin: '0px',
        },
    },
    expansionPanelRoot: {
        backgroundColor: '#dee1eb',
    },
    expansionSummaryRoot: {
        backgroundColor: '#dfe4ed',
        color: '#7d90aa',
        fontSize: '12px',
        height: '60px',
        minHeight: '60px',
        paddingLeft: '14px',
        paddingRight: '0px',
        textTransform: 'uppercase',
        '&.Mui-expanded': {
            height: '60px',
            minHeight: '60px',
        },
    },
    expandSummaryContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&.Mui-expanded img:nth-child(3)': {
            transform: 'rotate(90deg)',
        },
        paddingRight: '11px',
    },
    expandSummaryLabel: {
        marginLeft: '10px',
        flexGrow: 1,
    },
    expansionSummaryExpanded: {
        backgroundColor: '#dfe4ed',
        color: '#7d90aa',
    },
    expansionPanelDetails: {
        backgroundColor: '#f0f3f9',
        padding: '6px',
    },
    expandCollapseIcon: {
        padding: '3px !important',
        filter: hexColorToCSSFilter('#7e8cac'),
        marginRight: '0px',
        '&.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    },
    expansionSummaryIcon: {
        width: '25px',
        height: '25px',
        filter: hexColorToCSSFilter('#7e8cac'),
    },
    expansionSummaryIconGroup: {
        width: '35px',
        height: '35px',
        marginLeft: '-7px',
    },
    list: {
        width: '100%',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '62px',
        paddingLeft: '14px',
        paddingRight: '14px',
        color: '#7d90aa',
    },
    listItemLabel: {
        flexGrow: 1,
        fontSize: '12px',
        color: '#7d90aa',
        marginLeft: '14px',
    },
    listItemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '0px',
    },
    listItemIcon: {
        padding: '2px',
        marginLeft: '14px',
        height: '32px',
        width: '32px',
    },
    listItemIconSmaller: {
        padding: '4px',
    },
    listItemImage: {
        width: '32px',
        height: '32px',
        filter: hexColorToCSSFilter('#ffffff'),
        margin: '-4px',
    },
    listItemAllFiltersClose: {
        marginLeft: '10px',
    },
    listItemImageClose: {
        padding: '4px',
    },
    divider: {
        backgroundColor: '#bec7d4',
        height: '1px',
        position: 'absolute',
        bottom: 0,
        left: '0px',
        right: '0px',
    },
    machineTitle: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '45%',
    },
    machineIcons: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    openMachineIcon: {
        padding: '8px',
    },
    openMachineIconImage: {
        filter: hexColorToCSSFilter('#7d90aa'),
        width: '31px',
        height: '31px',
    },
    machineStatusIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '7px',
    },
    machinePath: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});

const ID_SELECTED_MACHINES = 'SELECTED_MACHINES';

/** Returns highest-level group type for each group of selected machines (i.e. prefer client over site, site over cluster) */
function getMachineGroups(item) {
    if (item.previous_clusters) {
        // Reached a machine - that means it's an individual machine chosen inside a cluster
        return item.selected ? [item] : [];
    }

    if (
        item.selectedMachines &&
        item.selectedMachines.length === item.machineCount
    ) {
        // Reached a client/site/cluster that has all its children selected
        return [item];
    }

    let groups = [];
    item.children.forEach((child) => {
        groups = groups.concat(getMachineGroups(child));
    });

    return groups;
}

/** Groups machines by cluster / site / client */
function groupMachines(machines) {
    // First pass - count the number of selected machines under each cluster/site/client
    const clients = {};
    machines.forEach((machine) => {
        const cluster = machine.parent;
        const site = cluster.parent;
        const client = site.parent;

        cluster.selectedMachines = [];
        site.selectedMachines = [];
        client.selectedMachines = [];

        clients[client._id] = client;
        machine.selected = true;
    });

    machines.forEach((machine) => {
        const cluster = machine.parent;
        const site = cluster.parent;
        const client = site.parent;

        cluster.selectedMachines.push(machine);
        site.selectedMachines.push(machine);
        client.selectedMachines.push(machine);
    });

    // Second pass - get highest-order groups for the machines
    let groups = [];
    Object.values(clients).forEach((client) => {
        calculateMachineCount(client);
        groups = groups.concat(getMachineGroups(client));
    });

    // Third pass - take out any single machine groups and place them into a group of their own ("Selected Machines")
    let singleMachines = groups.filter((g) => !g.selectedMachines);
    groups = groups.filter((g) => g.selectedMachines);

    if (singleMachines.length > 0) {
        groups.push({
            _id: ID_SELECTED_MACHINES,
            selectedMachines: singleMachines,
        });
    }

    return groups;
}

/** Overview filters applied - showing the machines selected for the overview filter,
 * grouped by cluster/site/client */
export default function OverviewFiltersApplied(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.overview.filter);
    const [groups, setGroups] = useState([]);
    const [isExpanded, setExpanded] = useState({});

    useFilterChanged(filter, () => {
        // Selected machines changed - place the machines into groups (by cluster/site/client).
        const machines = clone(filter.selectedMachines);
        const machineGroups = groupMachines(machines);

        // Sort by name (making sure "Selected Machines" group is at the end)
        machineGroups.sort((a, b) => {
            if (a._id === ID_SELECTED_MACHINES) return 1;
            return formatClientTitle(a).localeCompare(formatClientTitle(b));
        });

        // Sort each selected machine by name
        machineGroups.forEach((group) => {
            group.selectedMachines.sort((a, b) => {
                return a.alias.localeCompare(b.alias);
            });
        });

        setGroups(machineGroups);
    });

    const removeSelectedMachines = (machines) => {
        const itemsCopy = clone(filter.clients);
        const selectedMachinesCopy = clone(filter.selectedMachines);

        machines.forEach((machine) => {
            const machineCopy = findItem(itemsCopy, machine._id);
            unselectItem(machineCopy);

            const machineIndex = selectedMachinesCopy.findIndex(
                (m) => m._id === machine._id
            );
            selectedMachinesCopy.splice(machineIndex, 1);
        });

        dispatch(
            setOverviewFilter({
                clients: itemsCopy,
                selectedMachines: selectedMachinesCopy,
                allMachinesSelected: false,
            })
        );
    };

    const onOpenMachine = (machineId) => {
        // Open single machine view for the selected machine ID (in a new window)
        const win = window.open(`/machines/${machineId}/dashboard`, '_blank');
        win.focus();
    };

    return (
        <InnerWindow
            className={clsx(classes.window, props.className)}
            title={'Filters Applied'}
            loading={props.loading}
            icon={closeIcon}
            onIconClick={() => {
                if (props.loading) return;

                // Reset selection - move to all machines selection
                const itemsCopy = clone(filter.clients);
                itemsCopy.forEach((c) => markItem(c, false));

                dispatch(
                    setOverviewFilter({
                        allMachinesSelected: true,
                        selectedMachines: findAllMachines(filter.clients),
                        clients: itemsCopy,
                    })
                );
            }}
        >
            <div className={classes.content}>
                {groups.map((group) => (
                    <ExpansionPanel
                        key={group._id}
                        className={classes.expansionPanelContainer}
                        expanded={
                            isExpanded[group._id] === undefined ||
                            isExpanded[group._id]
                        }
                        onChange={(event, expanded) => {
                            setExpanded({
                                ...isExpanded,
                                [group._id]: expanded,
                            });
                        }}
                    >
                        <ExpansionPanelSummary
                            classes={{
                                expanded: classes.expansionSummaryExpanded,
                                root: classes.expansionSummaryRoot,
                                content: classes.expandSummaryContent,
                            }}
                        >
                            <img
                                src={
                                    group._id === ID_SELECTED_MACHINES
                                        ? machineIcon
                                        : clientIcon
                                }
                                alt={formatClientTitle(group)}
                                className={clsx(
                                    classes.expansionSummaryIcon,
                                    group._id === ID_SELECTED_MACHINES
                                        ? null
                                        : classes.expansionSummaryIconGroup
                                )}
                            />
                            <div className={classes.expandSummaryLabel}>
                                {group._id === ID_SELECTED_MACHINES
                                    ? 'Selected Machines'
                                    : formatClientTitle(group)}
                                {` (${group.selectedMachines.length})`}
                            </div>
                            <img
                                src={collapsedIcon}
                                className={classes.expandCollapseIcon}
                                alt="Expand"
                            />

                            <IconButton
                                className={clsx(
                                    classes.listItemIcon,
                                    classes.listItemAllFiltersClose
                                )}
                                style={{
                                    backgroundColor: '#7e8cac',
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    removeSelectedMachines(
                                        group.selectedMachines
                                    );
                                }}
                            >
                                <img
                                    className={clsx(
                                        classes.listItemImage,
                                        classes.listItemImageClose
                                    )}
                                    src={removeFilterIcon}
                                    alt={'Remove All Filters'}
                                />
                            </IconButton>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            classes={{ root: classes.expansionPanelDetails }}
                        >
                            <List className={classes.list}>
                                {group.selectedMachines.map((machine) => (
                                    <ListItem
                                        key={machine._id}
                                        disableGutters
                                        className={classes.listItem}
                                    >
                                        <div className={classes.machineTitle}>
                                            <div>{machine.alias}</div>
                                            <div
                                                className={classes.machinePath}
                                            >
                                                {formatClientTitle(
                                                    machine.parent
                                                )}
                                            </div>
                                        </div>
                                        <div className={classes.machineIcons}>
                                            {[...machine.statusGroups].map(
                                                (s) => {
                                                    const status = STATUS_GROUPS.find(
                                                        (status) =>
                                                            status.title === s
                                                    );
                                                    return (
                                                        <img
                                                            key={s}
                                                            className={
                                                                classes.machineStatusIcon
                                                            }
                                                            src={status.icon}
                                                            alt={status.title}
                                                            data-tip={
                                                                status.title
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                            <IconButton
                                                onClick={() => {
                                                    onOpenMachine(machine._id);
                                                }}
                                                className={classes.listItemIcon}
                                            >
                                                <img
                                                    src={openMachineIcon}
                                                    className={
                                                        classes.openMachineIconImage
                                                    }
                                                    alt="Open Machine"
                                                />
                                            </IconButton>

                                            <IconButton
                                                className={clsx(
                                                    classes.listItemIcon,
                                                    classes.listItemAllFiltersClose
                                                )}
                                                style={{
                                                    backgroundColor: true
                                                        ? machine.color
                                                        : '#7e8cac',
                                                }}
                                                onClick={() => {
                                                    removeSelectedMachines([
                                                        machine,
                                                    ]);
                                                }}
                                            >
                                                <img
                                                    className={clsx(
                                                        classes.listItemImage,
                                                        classes.listItemImageClose
                                                    )}
                                                    src={removeFilterIcon}
                                                    alt={'Remove Filters'}
                                                />
                                            </IconButton>
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </div>
        </InnerWindow>
    );
}
