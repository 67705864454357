import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
    STATUS_GROUPS,
    TYPE_OPERATION_REQUIRED,
    TYPE_STATUS,
    TYPE_WARNING,
} from './StatusGroups';
import ReactTooltip from 'react-tooltip';
import GoogleMapReact from 'google-map-react';
import Config from '../../modules/Config';
import { usePosition } from 'use-position';
import myLocationIcon from '../../res/images/my_location.svg';
import machineCircleIcon from '../../res/images/machine_circle_arrow.svg';
import {
    fitMarkersInBounds,
    getDistanceBetweenPoints,
} from '../../modules/MapUtils';
import { useFilterChanged } from '../../store/reducers/overview';
import Badge from '@material-ui/core/Badge';
import { getAllSites } from '../../modules/MachineUtils';
import useSupercluster from 'use-supercluster';
import Button from '../../components/Button';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import {
    setBottomSelectedMachines,
    setMapSelectedMachines,
} from '../../store/actions/overview';

// Minimal distance (in meters) between machine and site - below that distance,
// Do not show a line between them
const MIN_CLIENT_SITE_DISTANCE = 200;

const useStyles = makeStyles({
    container: {
        height: '100%',
        width: '100%',
    },
    machineTopRightBadge: {
        top: 0,
        right: '25%',
    },
    machineTopLeftBadge: {
        top: 0,
        left: '25%',
    },
    machineMarker: {
        cursor: 'pointer',
    },
    siteMarker: {
        height: '10px',
        width: '10px',
        backgroundColor: '#000000',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    groupTopRightBadge: {
        top: '15%',
        right: '15%',
    },
    groupTopLeftBadge: {
        top: '20%',
        left: '20%',
        width: '30px',
        height: '30px',
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        border: '2px solid #000000',
        color: '#000000',
        fontSize: '10px',
        fontWeight: 'bold',
    },
    tooltip: {
        borderRadius: '6px',
        fontSize: '14px',
        color: '#000000',
        backgroundColor: '#ffffff',
        opacity: '1 !important',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    tooltipRow: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '8px',
    },
    tooltipLabel: {
        width: '72px',
    },
    tooltipValue: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
    },
    tooltipMachineClusterValue: {
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    tooltipIcon: {
        height: '17px',
        width: '17px',
        marginLeft: '5px',
    },
    tooltipOpenMachine: {
        width: '180px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        '& img': {
            filter: hexColorToCSSFilter('#000000'),
        },
    },
    tooltipMachineClusterLabel: {
        width: '165px',
    },
    machineMarkerSelected: {
        boxShadow: '0px 0px 0px 10px rgba(125,144,170,0.55)',
        borderRadius: '50%',
    },
    groupMarkerSelected: {
        boxShadow: 'inset 0px 0px 0px 10px rgba(125,144,170,0.55)',
        borderRadius: '50%',
    },
});

/** Represents current location marker */
function MyLocationMarker(props) {
    const classes = useStyles();

    return (
        <div>
            <img
                src={myLocationIcon}
                data-tip={'You are here'}
                alt="You are here"
            />
            <ReactTooltip
                border
                borderColor={'#000000'}
                place="top"
                type="light"
                effect="solid"
                className={classes.tooltip}
            />
        </div>
    );
}

/** Represents a single machine marker - shows both status and warning icons */
function MachineMarker(props) {
    const classes = useStyles();

    const machine = props.machine;
    const cluster = machine.parent;
    const site = cluster.parent;
    const client = site.parent;

    // Find the machine's status (this will be the main icon)
    const status = STATUS_GROUPS.filter(
        (s) => s.type === TYPE_STATUS
    ).find((s) => machine.statusGroups.has(s.title));

    // Find the machine's operation required and warning statuses (these will be the secondary icons)
    const operationRequired = STATUS_GROUPS.filter(
        (s) => s.type === TYPE_OPERATION_REQUIRED
    ).find((s) => machine.statusGroups.has(s.title));
    const warning = STATUS_GROUPS.filter(
        (s) => s.type === TYPE_WARNING
    ).find((s) => machine.statusGroups.has(s.title));

    let topLeftIcon = null;
    let topRightIcon = null;

    if (operationRequired) {
        topLeftIcon = operationRequired.icon;
        topRightIcon = warning ? warning.icon : null;
    } else if (warning) {
        topLeftIcon = warning.icon;
    }

    const openMachine = () => {
        // Open single machine view for the selected machine ID (in a new window)
        const win = window.open(`/machines/${machine._id}/dashboard`, '_blank');
        win.focus();
    };

    return (
        <Badge
            overlap="circle"
            classes={{
                anchorOriginTopLeftCircle: classes.machineTopLeftBadge,
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            badgeContent={<img src={topLeftIcon} alt="Status" />}
            invisible={!topLeftIcon}
        >
            <Badge
                overlap="circle"
                classes={{
                    anchorOriginTopRightCircle: classes.machineTopRightBadge,
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                badgeContent={<img src={topRightIcon} alt="Status" />}
                invisible={!topRightIcon}
            >
                <img
                    className={clsx(
                        classes.machineMarker,
                        machine.mapSelected
                            ? classes.machineMarkerSelected
                            : null
                    )}
                    src={status.icon}
                    data-tip
                    data-for={machine._id}
                    onClick={() => {
                        props.onMachineClicked([machine]);
                    }}
                    alt="Machine"
                />
            </Badge>

            <ReactTooltip
                id={machine._id}
                border
                borderColor={'#000000'}
                place="top"
                type="light"
                effect="solid"
                delayHide={1000}
                clickable={true}
                className={classes.tooltip}
            >
                <div className={classes.tooltipRow}>
                    <div className={classes.tooltipLabel}>Client:</div>
                    <div className={classes.tooltipValue}>{client.name}</div>
                </div>
                <div className={classes.tooltipRow}>
                    <div className={classes.tooltipLabel}>Site:</div>
                    <div className={classes.tooltipValue}>{site.name}</div>
                </div>
                <div className={classes.tooltipRow}>
                    <div className={classes.tooltipLabel}>Cluster:</div>
                    <div className={classes.tooltipValue}>{cluster.name}</div>
                </div>
                <div className={classes.tooltipRow}>
                    <div className={classes.tooltipLabel}>Machine:</div>
                    <div className={classes.tooltipValue}>
                        {machine.alias}{' '}
                        {topLeftIcon && (
                            <img
                                className={classes.tooltipIcon}
                                src={topLeftIcon}
                                alt="Status"
                            />
                        )}
                        {topRightIcon && (
                            <img
                                className={classes.tooltipIcon}
                                src={topRightIcon}
                                alt="Status"
                            />
                        )}
                        <img
                            className={classes.tooltipIcon}
                            src={status.icon}
                            alt={status.title}
                        />
                    </div>
                </div>
                <div className={classes.tooltipRow}>
                    <Button
                        className={classes.tooltipOpenMachine}
                        label={'Open Machine'}
                        rightIcon={machineCircleIcon}
                        onClick={openMachine}
                        small
                    />
                </div>
            </ReactTooltip>
        </Badge>
    );
}

/** Represents a site marker (black dot) */
function SiteMarker(props) {
    const classes = useStyles();
    const site = props.site;
    const client = site.parent;

    return (
        <div className={classes.siteMarker} data-tip data-for={site._id}>
            <ReactTooltip
                id={site._id}
                border
                borderColor={'#000000'}
                place="top"
                type="light"
                effect="solid"
                delayHide={1000}
                clickable={true}
                className={classes.tooltip}
            >
                <div className={classes.tooltipRow}>
                    <div className={classes.tooltipLabel}>Client:</div>
                    <div className={classes.tooltipValue}>{client.name}</div>
                </div>
                <div className={classes.tooltipRow}>
                    <div className={classes.tooltipLabel}>Site:</div>
                    <div className={classes.tooltipValue}>{site.name}</div>
                </div>
            </ReactTooltip>
        </div>
    );
}

/** Represents a marker of a cluster (group) of machines */
function MachineClusterMarker(props) {
    const classes = useStyles();
    const machines = props.machines;
    const id = props.id.toString();

    // Determine which group icon to use - go over all child machines,
    // and determine the "worst" status (defined using the `priority` field of each status
    // in the STATUS_GROUPS array).

    // Find all machine statuses in this cluster
    let allStatuses = new Set();
    const statusCounts = {}; // Number of machines for each status
    machines.forEach((m) => {
        m.statusGroups.forEach((s) => {
            allStatuses.add(s);
            if (!statusCounts[s]) statusCounts[s] = 0;
            statusCounts[s]++;
        });
    });
    allStatuses = [...allStatuses].map((s) =>
        STATUS_GROUPS.find((x) => x.title === s)
    );

    // Find main icon to use = status of the "worst" machine (e.g. offline is worst than ready)
    const status = allStatuses
        .filter((s) => s.type === TYPE_STATUS) // We care only about statuses (not warnings, etc)
        .sort((a, b) => b.priority - a.priority)[0]; // Get the "worst" status (e.g. offline is worst than ready)

    // Find secondary icons to use (warning / operation required), if any
    const operationRequired = allStatuses.find(
        (s) => s.type === TYPE_OPERATION_REQUIRED
    );
    const warning = allStatuses.find((s) => s.type === TYPE_WARNING);

    let topRightIcon1 = null;
    let topRightIcon2 = null;

    if (operationRequired) {
        topRightIcon1 = operationRequired.icon;
        topRightIcon2 = warning ? warning.icon : null;
    } else if (warning) {
        topRightIcon1 = warning.icon;
    }

    const allMachinesSelected = machines.every((m) => m.mapSelected);

    return (
        <Badge
            overlap="circle"
            classes={{
                anchorOriginTopLeftCircle: classes.groupTopLeftBadge,
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            badgeContent={machines.length}
            max={9999}
        >
            <Badge
                overlap="circle"
                classes={{
                    anchorOriginTopRightCircle: classes.groupTopRightBadge,
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                badgeContent={
                    <div>
                        {topRightIcon2 && (
                            <img src={topRightIcon2} alt="Status" />
                        )}
                        <img src={topRightIcon1} alt="Status" />
                    </div>
                }
                invisible={!topRightIcon1}
            >
                <img
                    className={clsx(
                        classes.machineMarker,
                        allMachinesSelected ? classes.groupMarkerSelected : null
                    )}
                    src={status.groupIcon}
                    data-tip
                    data-for={id}
                    onClick={() => {
                        props.onMachineClicked(machines);
                    }}
                    alt={status.title}
                />
            </Badge>

            <ReactTooltip
                id={id}
                border
                borderColor={'#000000'}
                place="top"
                type="light"
                effect="solid"
                delayHide={1000}
                clickable={true}
                className={classes.tooltip}
            >
                {STATUS_GROUPS.map((s) => {
                    // See how many machines are found with this specific status
                    const machineCount = statusCounts[s.title] || 0;

                    // Don't show this line if the machine count is zero, and this status is a warning / operation required
                    if (s.type !== TYPE_STATUS && machineCount === 0)
                        return null;

                    return (
                        <div key={s.title} className={classes.tooltipRow}>
                            <div className={classes.tooltipMachineClusterLabel}>
                                {s.title}:
                            </div>
                            <div
                                className={clsx(
                                    classes.tooltipValue,
                                    classes.tooltipMachineClusterValue
                                )}
                            >
                                {machineCount}
                                <img
                                    className={classes.tooltipIcon}
                                    src={s.icon}
                                    alt={s.title}
                                />
                            </div>
                        </div>
                    );
                })}
            </ReactTooltip>
        </Badge>
    );
}

/** Returns list of machines in a specified cluster (could be a single machine marker,
 * could be a group of markers) */
function getMachinesFromCluster(cluster, supercluster) {
    const isCluster = cluster.properties.cluster;
    let machines = [];

    if (!isCluster) {
        const machine = cluster.properties.machine;
        machines.push(machine);
    } else {
        const machinesInCluster = supercluster
            .getLeaves(cluster.id, Infinity)
            .map((p) => p.properties.machine);
        machines = machines.concat(machinesInCluster);
    }

    return machines;
}

/** Draws client site lines (lines between machine markers (or groups of machines) and their parent site).
 * The lines are based on current map zoom - the visible machine markers, groups of markers
 * Returns a list of client-site polygons that were drawn into the map. */
function drawClientSiteLines(googleMaps, map, clusters, supercluster) {
    // Get all visible machines (whether by themselves or inside a machine group)
    let visibleMachines = [];

    clusters.forEach((cluster) => {
        visibleMachines = visibleMachines.concat(
            getMachinesFromCluster(cluster, supercluster)
        );
    });

    const lines = [];

    // Draw the lines (polygon)
    clusters.forEach((cluster) => {
        // Machines under current cluster
        let machines = getMachinesFromCluster(cluster, supercluster);

        // Calculate distances between each machine and its site (we
        const siteDistances = {};

        machines.forEach((machine) => {
            const { latitude, longitude } = machine.latest_location;
            const site = machine.parent.parent;

            const distance = getDistanceBetweenPoints(
                latitude,
                longitude,
                site.location.latitude,
                site.location.longitude
            );

            if (distance < MIN_CLIENT_SITE_DISTANCE) {
                // Didn't pass minimal distance - don't show a line for it
                return;
            }

            siteDistances[site._id] = site.location;
        });

        if (Object.values(siteDistances).length === 0) {
            // No lines to display
            return;
        }

        // Source of the line = current cluster
        const [longitude, latitude] = cluster.geometry.coordinates;

        // Draw one or more lines - from the current cluster towards the different parent sites
        Object.values(siteDistances).forEach((site) => {
            const coordinates = [
                { lat: latitude, lng: longitude },
                { lat: site.latitude, lng: site.longitude },
            ];

            const line = new googleMaps.Polyline({
                path: coordinates,
                geodesic: true,
                strokeColor: '#000000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
            });

            line.setMap(map);
            lines.push(line);
        });
    });

    return lines;
}

/** Removes input client site lines from a map */
function clearClientSiteLines(lines) {
    if (!lines) return;

    // Each line is a Google Maps Polygon (https://developers.google.com/maps/documentation/javascript/shapes#polyline_remove)
    lines.forEach((line) => {
        line.setMap(null);
    });
}

/** Dashboard map - showing the machines on the map */
export default function DashboardMap(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const mapRef = useRef(null);
    const currentLocation = usePosition();
    const filter = useSelector((state) => state.overview.filter);
    const visibleStatusGroups = props.statusGroups.filter((s) => s.visible);
    const [zoom, setZoom] = useState(1);
    const [center, setCenter] = useState([0, 0]);
    const [currentBounds, setCurrentBounds] = useState(null);
    const [currentZoom, setCurrentZoom] = useState(1);
    const [googleMaps, setGoogleMaps] = useState(null);
    const [clientSiteLines, setClientSiteLines] = useState([]);
    const mapSelectedMachines = useSelector(
        (state) => state.overview.mapSelectedMachines
    );

    useEffect(() => {
        if (!props.clientSiteViewEnabled) {
            // Remove existing client site lines
            clearClientSiteLines(clientSiteLines);
            setClientSiteLines([]);
        } else {
            // Add client site lines
            setClientSiteLines(
                drawClientSiteLines(
                    googleMaps.maps,
                    googleMaps.map,
                    clusters,
                    supercluster
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clientSiteViewEnabled]);

    // Prepare the list of machines to display on the map

    // If the user didn't filter any machines, show all machines
    const machines = filter.selectedMachines;

    const visibleMachines = machines
        // Show only machines with a latitude/longitude
        .filter((m) => m.latest_location)
        // Show only machines the belong to status groups that are marked as visible
        .filter((m) =>
            visibleStatusGroups.some((s) => m.statusGroups.has(s.title))
        );

    // Get the parent sites of all machines on maps (will be displayed as simple black dots)
    const sites = getAllSites(machines);

    // Convert from machines to points - this is used for clustering (grouping machines into clusters,
    // depending on zoom / bounds)

    const points = visibleMachines.map((machine) => ({
        type: 'Feature',
        properties: {
            cluster: false,
            machine: {
                _id: machine._id,
                alias: machine.alias,
                statusGroups: machine.statusGroups,
                parent: machine.parent,
                mapSelected: mapSelectedMachines[machine._id],
            },
        },
        geometry: {
            type: 'Point',
            coordinates: [
                parseFloat(machine.latest_location.longitude),
                parseFloat(machine.latest_location.latitude),
            ],
        },
    }));

    // This hook (which uses the supercluster library) does the job of grouping the different machine
    // points into clusters.
    const { clusters, supercluster } = useSupercluster({
        points: points,
        bounds: currentBounds,
        zoom: currentZoom,
        options: { radius: 75, maxZoom: 20 },
    });

    useEffect(() => {
        let visibleMachines = [];

        clusters.forEach((cluster) => {
            visibleMachines = visibleMachines.concat(
                getMachinesFromCluster(cluster, supercluster)
            );
        });

        props.onVisibleMachinesChanged(visibleMachines);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clusters]);

    useFilterChanged(filter, () => {
        // First time loading up the machines - fit all machine markers into view
        const points = visibleMachines.map((m) => ({
            latitude: m.latest_location.latitude,
            longitude: m.latest_location.longitude,
        }));

        fitMarkersInBounds(points, mapRef.current, (res) => {
            // In case this is the result of a no-longer-relevant call
            if (res.mapMarkers.length !== points.length) return;

            setCenter(res.center);
            setZoom(res.zoom > 15 ? 15 : res.zoom);
        });
    });

    const onMachineMapClicked = (machines) => {
        const newSelectedMachines = {};

        // Handle the case where we already selected a machine (or group of machines), we then zoom out, and select a parent
        // group that includes that already selected machines
        const newValue = !machines.every((m) => mapSelectedMachines[m._id]);

        machines.forEach((machine) => {
            newSelectedMachines[machine._id] = newValue;
        });

        dispatch(setMapSelectedMachines(newSelectedMachines));

        // When a machine / group of machines - is clicked on the map, we also select it for the bottom view
        // (this replaces any existing selections)
        const newBottomSelectedMachines = machines.filter(
            (m) => newSelectedMachines[m._id]
        );
        dispatch(setBottomSelectedMachines(newBottomSelectedMachines));
    };

    return (
        <div ref={mapRef} className={clsx(classes.container, props.className)}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: Config.GMAPS_API_KEY,
                }}
                options={{}}
                zoom={zoom}
                center={center}
                onChange={({ zoom, bounds }) => {
                    setCurrentZoom(zoom);
                    setCurrentBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat,
                    ]);
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={(gm) => setGoogleMaps(gm)}
            >
                {currentLocation &&
                    currentLocation.latitude &&
                    currentLocation.longitude && (
                        <MyLocationMarker
                            lat={currentLocation.latitude}
                            lng={currentLocation.longitude}
                            width="26px"
                            height="26px"
                        />
                    )}

                {clusters.map((cluster) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const isCluster = cluster.properties.cluster;

                    if (isCluster) {
                        // Cluster of machines
                        const machinesInCluster = supercluster
                            .getLeaves(cluster.id, Infinity)
                            .map((p) => p.properties.machine);

                        return (
                            <MachineClusterMarker
                                key={cluster.id}
                                id={cluster.id}
                                lat={latitude}
                                lng={longitude}
                                machines={machinesInCluster}
                                onMachineClicked={onMachineMapClicked}
                            />
                        );
                    } else {
                        // Single machine marker
                        const machine = cluster.properties.machine;

                        return (
                            <MachineMarker
                                key={machine._id}
                                lat={latitude}
                                lng={longitude}
                                machine={machine}
                                onMachineClicked={onMachineMapClicked}
                            />
                        );
                    }
                })}

                {sites.map((s) => (
                    <SiteMarker
                        key={s._id}
                        lat={s.location.latitude}
                        lng={s.location.longitude}
                        site={s}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
}
