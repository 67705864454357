import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import InnerWindow from '../../components/InnerWindow';
import clsx from 'clsx';
import MachineList from '../../components/MachineList';
import { hexColorToCSSFilter } from '../../modules/CSSUtils';
import {
    calculateMachineCount,
    cloneItemOnly,
    filterAllItems,
} from '../../modules/MachineUtils';
import machineIcon from '../../res/images/machine.svg';
import { IconButton } from '@material-ui/core';
import openMachineIcon from '../../res/images/machine_circle_arrow.svg';
import { useHistory } from 'react-router-dom';
import { STATUS_GROUPS } from './StatusGroups';
import clone from 'clone';

const useStyles = makeStyles({
    window: {},
    content: {
        backgroundColor: '#f2f5f9',
        height: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    list: {
        width: '100%',
        height: '100%',
        flexGrow: 1,
    },
    itemRightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemMachineCount: {
        color: '#7d90aa',
        opacity: 0.8,
        fontSize: '16px',
        fontWeight: 300,
        marginLeft: '5px',
        width: '30px',
    },
    itemMachineIcon: {
        width: '21px',
        height: '21px',
        filter: hexColorToCSSFilter('#7d90aa'),
    },
    openMachineIcon: {
        padding: '8px',
    },
    openMachineIconImage: {
        filter: hexColorToCSSFilter('#7d90aa'),
    },
    itemLevelIcon: {
        filter: hexColorToCSSFilter('#7d90aa'),
    },
    itemStatusIcon: {
        width: '20px',
        height: '20px',
        marginRight: '20px',
    },
    machineItemStatusIcon: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
    },
});

function shouldShowInStatusTree(item) {
    if (item.selected) return true; // Item is directly selected
    if (!item.children) return false; // Reached a non-selected machine

    // Item should be shown in tree if any of its children (direct or indirect) are selected
    return item.children.some((c) => shouldShowInStatusTree(c));
}

/** Goes over a client tree and returns only the part of the tree that was
 * tagged for that status  */
function buildTreeForStatus(filter, item, status, hasClientCategoriesSelected) {
    if (!filter.allMachinesSelected && !shouldShowInStatusTree(item)) return;
    if (filter.allMachinesSelected && !item.visible) return;
    if (status && (!item.statusGroups || !item.statusGroups.has(status.title)))
        return;

    // Item should be returned
    const newItem = cloneItemOnly(item); // Make a copy (only one level deep)

    if (item.children) {
        // Go over the item's children
        newItem.children = [];
        item.children.forEach((c) => {
            const newChild = buildTreeForStatus(
                filter,
                c,
                status,
                hasClientCategoriesSelected
            );
            if (newChild) {
                newItem.children.push(newChild);
            }
        });
    }

    if (status) newItem.icon = status.icon;

    return newItem;
}

/** Builds a grouped status tree from the clients (where each client is grouped
 * into one or more statuses (error, ready, maintenance required, etc.) */
function buildStatusTree(filter) {
    const tree = [];
    const hasClientCategoriesSelected =
        filter.filterClientCategories &&
        Object.keys(filter.filterClientCategories).some((category) =>
            Object.keys(filter.filterClientCategories[category].values).some(
                (value) =>
                    filter.filterClientCategories[category].values[value]
                        .selected
            )
        );

    const allClientsNode = {
        _id: 'All Machines',
        name: 'All Machines',
        children: [],
    };

    const itemsCopy = clone(filter.clients);
    const newItems = filterAllItems(
        itemsCopy,
        '',
        filter.selectedCountries,
        filter.selectedRegions,
        null,
        filter.filterClientCategories,
        null,
        false,
        false
    );

    // Add an "All machines" node (just showing all machines)
    newItems
        .filter((c) => (filter.allMachinesSelected ? c.visible : c.selected))
        .forEach((client) => {
            const newClient = buildTreeForStatus(
                filter,
                client,
                null,
                hasClientCategoriesSelected
            );
            if (newClient) allClientsNode.children.push(newClient);
        });

    calculateMachineCount(allClientsNode);
    tree.push(allClientsNode);

    // Build a status group tree (each top-level node is a status). Note that
    // client/site/cluster/machine nodes can appear under multiple status nodes.

    STATUS_GROUPS.forEach((status) => {
        const statusNode = {
            _id: status.title,
            name: status.title,
            children: [],
        };

        newItems
            .filter((c) =>
                filter.allMachinesSelected ? c.visible : c.selected
            )
            .forEach((client) => {
                const newClient = buildTreeForStatus(filter, client, status);
                if (newClient) {
                    newClient.icon = status.icon;
                    statusNode.children.push(newClient);
                }
            });

        calculateMachineCount(statusNode);

        statusNode.icon = status.icon;
        tree.push(statusNode);
    });

    return tree;
}

/** Machines dashboard inner window - showing client/site/cluster/machine view,
 * grouped by status and error state */
export default function MachinesDashboard(props) {
    const classes = useStyles();
    const filter = useSelector((state) => state.overview.filter);
    const [items, setItems] = useState([]);
    const [itemsExpanded, setItemsExpanded] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!itemsExpanded) {
            // Items changed - re-build the status tree
            const tree = buildStatusTree(filter);
            setItems(tree);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.clients]);

    const onOpenMachine = (machineId) => {
        // Open single machine view for the selected machine ID (in a new tab)
        window.open(`/machines/${machineId}/dashboard`, '_blank');
    };

    const renderRightPart = (item) => {
        const isMachine = item.previous_clusters;
        const machineCount = item.machineCount;

        if (item.expanded) {
            setItemsExpanded(true);
        }

        if (!isMachine) {
            return (
                <div className={classes.itemRightSide}>
                    {item.icon && (
                        <img
                            src={item.icon}
                            className={classes.itemStatusIcon}
                            alt="Status"
                        />
                    )}
                    <img
                        src={machineIcon}
                        className={classes.itemMachineIcon}
                        alt="Machine Count"
                    />
                    <div className={classes.itemMachineCount}>
                        {machineCount}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classes.itemRightSide}>
                    {item.icon && (
                        <img
                            src={item.icon}
                            className={classes.machineItemStatusIcon}
                            alt="Status"
                        />
                    )}

                    <IconButton
                        onClick={() => {
                            onOpenMachine(item._id);
                        }}
                        className={classes.openMachineIcon}
                    >
                        <img
                            src={openMachineIcon}
                            className={classes.openMachineIconImage}
                            alt="Open Machine"
                        />
                    </IconButton>
                </div>
            );
        }
    };

    return (
        <InnerWindow
            loading={props.loading}
            title={'Machines Dashboard'}
            className={clsx(classes.window, props.className)}
        >
            <div className={classes.content}>
                <MachineList
                    className={classes.list}
                    items={items}
                    filter={''}
                    renderRightPart={renderRightPart}
                    hideExpansionMarks={true}
                    colors={{
                        labelColor: '#7d90aa',
                        backgroundColors: [
                            '#f1f4f9',
                            '#e6ebf1',
                            '#dee4eb',
                            '#dee4eb',
                            '#cfd7e0',
                        ],
                        itemLevelIconClass: classes.itemLevelIcon,
                    }}
                />
            </div>
        </InnerWindow>
    );
}
