export const SET_TOGGLE_BAR_STATE = 'SET_TOGGLE_BAR_STATE';
export const SET_SIDE_MENU_STATE = 'SET_SIDE_MENU_STATE';
export const SET_SINGLE_MACHINE = 'SET_SINGLE_MACHINE';
export const SET_TOGGLE_BAR_COMPONENT = 'SET_TOGGLE_BAR_COMPONENT';

export const TOGGLE_BAR_STATE_DASHBOARD = 'TOGGLE_BAR_STATE_DASHBOARD';
export const TOGGLE_BAR_STATE_TIMELINE = 'TOGGLE_BAR_STATE_TIMELINE';
export const TOGGLE_BAR_STATE_SG_KPI = 'TOGGLE_BAR_STATE_SG_KPI';
export const TOGGLE_BAR_STATE_ACTION = 'TOGGLE_BAR_STATE_ACTION';
export const TOGGLE_BAR_STATE_RESULTS = 'TOGGLE_BAR_STATE_RESULTS';
export const TOGGLE_BAR_STATE_KPIS = 'TOGGLE_BAR_STATE_KPIS';
export const TOGGLE_BAR_STATE_FILTER = 'TOGGLE_BAR_STATE_FILTER';
export const TOGGLE_BAR_STATE_FAULTS = 'TOGGLE_BAR_STATE_FAULTS';

export const SIDE_MENU_STATE_OVERVIEW = 'SIDE_MENU_STATE_OVERVIEW';
export const SIDE_MENU_STATE_MACHINES = 'SIDE_MENU_STATE_MACHINES';
export const SIDE_MENU_STATE_TIMELINE = 'SIDE_MENU_STATE_TIMELINE';
export const SIDE_MENU_STATE_STATS = 'SIDE_MENU_STATE_STATS';
export const SIDE_MENU_STATE_REPORTS = 'SIDE_MENU_STATE_REPORTS';
export const SIDE_MENU_STATE_ADMIN = 'SIDE_MENU_STATE_ADMIN';
export const SIDE_MENU_STATE_FW_UPDATE_STATUS =
    'SIDE_MENU_STATE_FW_UPDATE_STATUS';
export const SIDE_MENU_STATE_RECIPE_TYPES = 'SIDE_MENU_STATE_RECIPE_TYPES';
export const SET_APP_BAR_VISIBILITY = 'SET_APP_BAR_VISIBILITY';
export const APP_BAR_VISIBLE = 'APP_BAR_VISIBLE';
export const APP_BAR_HIDDEN = 'APP_BAR_HIDDEN';
export const APP_BAR_LOGO_ONLY = 'APP_BAR_LOGO_ONLY';

export function setAppBarVisibility(value) {
    return {
        type: SET_APP_BAR_VISIBILITY,
        value: value,
    };
}

export function setToggleBarState(value) {
    return {
        type: SET_TOGGLE_BAR_STATE,
        value: value,
    };
}

export function setSideMenuState(value) {
    return {
        type: SET_SIDE_MENU_STATE,
        value: value,
    };
}

export function setSingleMachine(value) {
    return {
        type: SET_SINGLE_MACHINE,
        value: value,
    };
}

export function setToggleBarComponent(value) {
    return {
        type: SET_TOGGLE_BAR_COMPONENT,
        value: value,
    };
}
