import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
    setSideMenuState,
    setToggleBarComponent,
    setToggleBarState,
    SIDE_MENU_STATE_OVERVIEW,
    TOGGLE_BAR_STATE_DASHBOARD,
} from '../../store/actions/ui';
import OverviewToggleBar from './OverviewToggleBar';
import MachinesDashboard from './MachinesDashboard';
import DashboardMapContainer from './DashboardMapContainer';
import MachinesTables from './MachinesTables';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    dashboardSubWindow: {
        width: '414px',
        maxWidth: '414px',
        marginRight: '31px',
    },
    mapSubWindow: {
        flexGrow: 1,
    },
    dashboardTopPart: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    dashboardBottomPart: {
        marginTop: '31px',
    },
});

/** Overview dashboard - dashboard view (machines by alert type + map) */
export default function OverviewDashboard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setToggleBarComponent(OverviewToggleBar));
        dispatch(setToggleBarState(TOGGLE_BAR_STATE_DASHBOARD));
        dispatch(setSideMenuState(SIDE_MENU_STATE_OVERVIEW));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.dashboardTopPart}>
                <MachinesDashboard
                    loading={props.loading}
                    className={classes.dashboardSubWindow}
                />
                <DashboardMapContainer
                    loading={props.loading}
                    className={classes.mapSubWindow}
                />
            </div>
            <MachinesTables
                loading={props.tableLoading}
                className={classes.dashboardBottomPart}
            />
        </div>
    );
}
