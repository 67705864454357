import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
    setSideMenuState,
    setToggleBarComponent,
    setToggleBarState,
    SIDE_MENU_STATE_OVERVIEW,
    TOGGLE_BAR_STATE_FAULTS,
    TOGGLE_BAR_STATE_FILTER,
} from '../../store/actions/ui';
import OverviewToggleBar from './OverviewToggleBar';
import MachineSelection from './MachineSelection';
import OverviewFiltersApplied from './OverviewFiltersApplied';
import MachinesTables from './MachinesTables';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vh - 115px)',
        width: '100%',
    },
});

/** Overview faults */
export default function OverviewFaults(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setToggleBarComponent(OverviewToggleBar));
        dispatch(setToggleBarState(TOGGLE_BAR_STATE_FAULTS));
        dispatch(setSideMenuState(SIDE_MENU_STATE_OVERVIEW));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.container}>
            <MachinesTables
                faults={true}
                loading={props.tableLoading}
                className={classes.dashboardBottomPart}
            />
        </div>
    );
}
